import axios from 'axios';
import {isEmpty,cloneDeep} from 'loadsh'
import IndianBoundary from '../IndianBoundary';
import {restClientService} from './RestClientService';
import * as L from "leaflet";
import GoogleMutant from "leaflet.gridlayer.googlemutant";
import { GestureHandling } from "leaflet-gesture-handling";

import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import { connect } from 'react-redux';
import { hereMapService } from '../containers/HereMap/HereMapService';
import { predictMovementConstants, trackingInaccuracyConstants, mapLayoutSource } from '../containers/LiveTracking/liveTrackingConstants';
import CONSTANTS from '../Utils/Constants';
import moment from 'moment-timezone';

class EnhancedLiveTrackingService {
   MAIN_QUEUE = [];
   dateTime = "";
   dataCallQueue = false;
   IS_QUEUE_RUN = false;
   myMovingMarker
   lastPlotted = {};
   firstpolyline
   feMarker;
   jobLocationMarker;
   lastPlotted={};
   actualLastLng ={};
   details;
   url;
   isUpdate = false;
   eta;
   isEmbed = false;
   trackLogSource;
   monthNames = ["Jan", "Feb", "March", "April", "May", "June","July", "Aug", "Sept", "Oct", "Nov", "Dec"];

   isFakePathRunning = false;
   tempLatLngList= []
   timeInterval
   speed = 40 
   rotationAngle
   previousRotationAngle
   previousDistance
   previousObj
   midPointDistance
  //------fakeEstimate
   trackLogsBuffer = [];
   meanSpeed;
   estimatedETA;
   trackLogsThreshold = 6;
   trackLogsLastUpdatedTime = null;
   fakeCountMovement = 0;
   initialPath = [];
   isMovingEnd = false;
   isRunning = false;
   trackUserLatLngList = [];
   time = 0;
   etaValue;
   updateEtaFromServer  = true;
   initialETA;
   nearBy = false;
   prevLatitutde;
   prevLongitude;
   updateETA = false;
   totalDistance = 0;
   isReCenterAllowed = true;
   zoomCounter = 0;
   userLatLngListTemp;
   googleMutantDummyObject = GoogleMutant; // dummy object used for googleMutant library


  
  jobIcon =  new L.Icon({
            iconUrl: require('../images/marker-icon.png'),
            iconRetinaUrl: require('../images/marker-icon.png'),
            iconAnchor: [12, 30],
            iconSize: [28, 41],
          })

    async initializeMap(obj,details,url,isEmbed,prevJob){
        try{
          let herePoweredAccount =  window.localStorage.getItem('isHerePoweredAccount') === 'true' ? true : false
          let cache_data;
          this.IS_QUEUE_RUN = false;
          if((!isEmpty(details.predictiveTrackingInfo) || (details.liveMonitoringDto && details.liveMonitoringDto.trackBaseDTOList)) 
            && !((obj && obj.props.trackingDetails && obj.props.trackingDetails.liveTrackingPreviousJobsInfo && obj.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled 
              &&(obj.props.previousJobCount==-1|| obj.props.previousJobCount>0)))){
                cache_data = this.handleTrackingWithCacheData(details);
                this.cacheData = cache_data;
                this.showPrevJobETA = false;
              } else if(details.liveMonitoringDto && details.liveMonitoringDto.trackBaseDTOList 
              && ((obj && obj.props.trackingDetails && obj.props.trackingDetails.liveTrackingPreviousJobsInfo && obj.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled 
                &&(obj.props.previousJobCount==-1|| obj.props.previousJobCount>0)))) {
                this.showPrevJobETA=true;
              }
          this.details = details;
          this.details.liveMonitoringDto = (cache_data && !isEmpty(cache_data["dateTime"]))?cache_data["liveMonitoringDto"]:this.details.liveMonitoringDto;
          this.details = cloneDeep(this.details);
          this.url = url;
          this.eta = (cache_data && !isEmpty(cache_data["dateTime"]))?cache_data["eta"]:"";
          this.etaValue =(cache_data &&  !isEmpty(cache_data["dateTime"]))?cache_data["etaValue"]:"";
          this.initialETA =(cache_data && !isEmpty(cache_data["dateTime"]))?cache_data["initialETA"]:"";
          this.updateETA = (cache_data && !isEmpty(cache_data["dateTime"]))?cache_data["updateETA"]:false;
          this.updateEtaFromServer = (cache_data && !isEmpty(cache_data["dateTime"]))?cache_data["updateEtaFromServer"]:true;
          this.time = 0;
          this.nearBy = (cache_data && !isEmpty(cache_data["dateTime"]))?cache_data["updateEtaFromServer"]:false;
          this.isEmbed = window.location.href.indexOf('&embed=true')!=-1?true:false; 
          this.retryFELocation=(this.details.feLat==undefined || this.details.feLat=='0.0' || this.details.feLat=='0')?true:false;
          this.isReCenterAllowed = true;
          let map;
          if(this.map && this.isEmbed){
            map = this.map;
          } else {
            if(this.map){
              this.map.remove();
            }
            L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
            let is_gesture = (details && (details.screen!=undefined && details.screen=="liveTracking"))?false:true;
            map = this.map = L.map('map',{closePopupOnClick: false,gestureHandling: is_gesture,attributionControl: false}).setView([28,77], 2);
            map.panTo(new L.LatLng(26,77));
            if(details && details.mapLayoutSource == mapLayoutSource.GOOGLE) {
              L.gridLayer.googleMutant({type: "roadmap"}).addTo(map);
            } else if(herePoweredAccount){
              hereMapService.getHereMapLayer(this.map);
            } else{
              L.tileLayer('https://map.fareye.co/styles/klokantech-basic/{z}/{x}/{y}.png', {maxZoom: 18,id: 'fareye'}).addTo(map);
            }
        }
          if(this.feMarker){
            this.map.removeLayer(this.feMarker);
          }
          if(this.jobLocationMarker){
            this.map.removeLayer(this.jobLocationMarker);
          }
          this.setFeIcon(details)
          // map.dragging.disable();
          this.addIndiaBoundary(map); 
          this.setJobLocationIcon(map,details,obj);
          if(!this.showPrevJobETA && details && details.liveMonitoringDto && details.liveMonitoringDto.jobEtaResponseDTO && this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList && this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0] && this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList) {
            this.initialPath = JSON.parse(JSON.stringify(this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList));
          }            
          this.resizeMap();
          this.map.on('zoomstart',
          function mapClickListen(e) {
            if(this.zoomCounter > 1){
              this.isReCenterAllowed = false;
            }
            this.zoomCounter++;
          }.bind(this)
     );
     this.map.on('dragstart', function(e) {
      this.isReCenterAllowed = false;
    }.bind(this));
          return map;
        } catch(err){
           console.log("err",err);
        }
      }

      setFeIcon (){
          if(this.details && this.details.customerInteractionThemeSettingsDTO && this.details.customerInteractionThemeSettingsDTO.feIcon){
            this.feIcon =  new L.Icon({
              iconUrl: this.details&& this.details.customerInteractionThemeSettingsDTO && this.details.customerInteractionThemeSettingsDTO.feIcon,
              iconRetinaUrl: this.details&& this.details.customerInteractionThemeSettingsDTO && this.details.customerInteractionThemeSettingsDTO.feIcon,
              // iconUrl: require('../images/ico.png'),
              // iconRetinaUrl: require('../images/ico.png'),
              iconAnchor: [11, 24],
              iconSize: [20, 25],
              className: 'u-turn-icon',
    
            })
          } else {
             this.feIcon = new L.DivIcon({
                        type: 'div',
                        iconSize: [100, 100],
                        iconAnchor: [16, 25],
                        html: '<div class="sonar-wrapper">'+'<div class="outer-circle">'+'<div class="inner-circle"></div>'+'<div class="sonar-wave"></div>'+'</div></div>'
                    });
          }
          if(this.details && this.details.customerInteractionThemeSettingsDTO && this.details.customerInteractionThemeSettingsDTO.jobLocationIcon){
            this.jobIcon =  new L.Icon({
              iconUrl: this.details&& this.details.customerInteractionThemeSettingsDTO && this.details.customerInteractionThemeSettingsDTO.jobLocationIcon,
              iconRetinaUrl: this.details&& this.details.customerInteractionThemeSettingsDTO && this.details.customerInteractionThemeSettingsDTO.jobLocationIcon,
              // iconUrl: require('../images/ico.png'),
              // iconRetinaUrl: require('../images/ico.png'),
              iconAnchor: [12, 24],
              iconSize: [20, 25],
              className: 'u-turn-icon',
    
            })
          }
      }

      addIndiaBoundary(map){
        var boundary = L.geoJson(IndianBoundary, {
            style: function(feature) {
                return {
                    color: '#9e9cab',
                    weight: 2
                };
            },
        });
        map.addLayer(boundary);
    }
    
    drawpathForGTS=(details)=>{
      let latLngList = [];
      latLngList.push({lat:details.jobLat,lng:details.jobLng});
      latLngList.push({lat:this.lastPlotted.latitude,lng:this.lastPlotted.longitude})
      this.map.fitBounds(latLngList, {paddingBottomRight: [20, 120],paddingTopLeft:[90,75]});
      if(this.firstpolyline){
        this.map.removeLayer(this.firstpolyline)
      }
      if(this.feMarker){
        this.map.removeLayer(this.feMarker);
      }
      // this.firstpolyline = new L.Polyline(latLngList, {
      //                                             color: '#05e862',
      //                                             weight: 3.5,
      //                                             opacity: 0.5,
      //                                             smoothFactor: 4
      //                                         });
      // this.firstpolyline.addTo(this.map);
      this.feMarker = L.marker([this.lastPlotted.latitude,this.lastPlotted.longitude],{
        icon:this.feIcon
        }).addTo(this.map);
    }
    
    setJobLocationIcon=(map,details,obj)=>{
          if(map){
            var jobBound,feBound;
            if(details && details.jobLat && details.jobLng){
              this.jobLocationMarker = L.marker([details.jobLat , details.jobLng] ,{
                  icon:this.jobIcon,
                  clickable:false
                  }).addTo(this.map);
                jobBound = new L.LatLng(details.jobLat, details.jobLng);
                details["jobLocationMarker"] = this.jobLocationMarker;
            } if(details && details.showTracking && details.feLat){
                this.feMarker = L.marker([details.feLat, details.feLng],{              // this is not one             // this is not one
                                      icon:this.feIcon,
                                      rotationAngle:this.findRotationAngle(0, 0, details.feLat, details.feLng)

                                  }).addTo(this.map);
                feBound = new L.LatLng(details.feLat,details.feLng);
                this.lastPlotted.latitude = JSON.parse(JSON.stringify(details.feLat));
                this.lastPlotted.longitude =JSON.parse(JSON.stringify(details.feLng));
                if(details && details.trackLogSource && details.trackLogSource=="GTS" && details.trackingType!="api"){
                    this.trackLogSource = "GTS";
                    this.drawpathForGTS(details)
                }
                else if(details.liveMonitoringDto && details.liveMonitoringDto.trackBaseDTOList 
                  && !((obj && obj.props.trackingDetails && obj.props.trackingDetails.liveTrackingPreviousJobsInfo && obj.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled 
                    &&(obj.props.previousJobCount==-1|| obj.props.previousJobCount>0)))){
                      this.getTrackData(details.liveMonitoringDto,600)
                } else {
                  // this.getTrackLogs();
                }
            }
             if(jobBound && feBound){
              this.setBoundMap(jobBound,feBound,map);
              if(this.isEmbed ){
                this.bindPopUp();
              }
             } else if(jobBound) {
                this.map.setView([details.jobLat,details.jobLng], 16)
             } else if(feBound){
               this.map.setView([details.feLat,details.feLng], 16)
             }
            }
      }

      updateView(bounds){
        if(this.isEmbed && this.map && this.map.getZoom()>12){
          let zoom = this.map.getZoom()-1;
          this.map.fitBounds(bounds, {maxZoom:zoom,paddingBottomRight: [20, 120],paddingTopLeft:[90,75]})
        } 
      }

     

      setBoundMap=(jobBound,feBound,map)=>{
        if(!this.isReCenterAllowed) return;
        var bounds;
        if(jobBound && feBound){
                bounds = new L.LatLngBounds( feBound,jobBound);
                map.fitBounds(bounds, {paddingBottomRight: [20, 120],paddingTopLeft:[90,75]})
                this.updateView(bounds);
              } else if(jobBound){
            bounds = new L.LatLngBounds(jobBound);
            map.fitBounds(bounds, {paddingBottomRight: [20, 120],paddingTopLeft:[90,75]})
          } else if (feBound){
            bounds = new L.LatLngBounds(feBound);
            map.fitBounds(bounds, {paddingBottomRight: [20, 120],paddingTopLeft:[90,75]})
          }
       }
       
      updateGTStracklog=(data)=>{
        if(data && data.lastLatitude && (!this.lastPlotted.latitude || (this.lastPlotted.latitude!=data.lastLatitude ||this.lastPlotted.longitude!=data.lastLongitude))){
          var mapPoints = [];
          var time = [];
          time.push(8000); 
          mapPoints.push([this.lastPlotted.latitude,this.lastPlotted.longitude]);
          mapPoints.push([data.lastLatitude,data.lastLongitude]);
          this.lastPlotted.latitude = JSON.parse(JSON.stringify(data.lastLatitude));
          this.lastPlotted.longitude = JSON.parse(JSON.stringify(data.lastLongitude));
          if(this.feMarker){
            this.map.removeLayer(this.feMarker);
          }
          this.createMarkerAndPolyLine(mapPoints,time);
        }
      }
          
      getTrackData(data,timeValue,isForward, handleBackTracklogs){
            if(this.IS_QUEUE_RUN || this.dataCallQueue){
                return;
            }
            if(this.details && this.details.trackLogSource=="GTS" && details.trackingType!="api"){
                 this.updateGTStracklog(data);
            } else {
              if (data != null && data != undefined) {
                this.dataCallQueue = true;
                 if(!data.isJobToRemove){
                     try{
                         if( data.lastUpdatedAt) {
                             this.dateTime = data.lastUpdatedAt;
                         }
                         this.MAIN_QUEUE.push(data);
                         this.plotPath(timeValue,isForward, handleBackTracklogs);
                     }catch(e){
                       console.log(e);
                     }
                 }
             }
             this.dataCallQueue = false;
            //  return this.eta;
            }
        }
    
    recalculateZoom(trackLog, timeoutMultiplier,timeValue){
        if(!timeoutMultiplier ){
          timeoutMultiplier = 1;
      }
      if(timeValue){
        timeValue = timeValue-(timeValue*.62);
      }
      if(this.map){
          setTimeout(this.plotBounds.bind(this,trackLog),timeValue*timeoutMultiplier);
        }
    }
    
    plotBounds(trackLog){
      if(!this.isReCenterAllowed) return;
          if(this.map){
              var bounds = [];
              bounds.push([trackLog.latitude,trackLog.longitude]);
              if(this.details && this.details.liveMonitoringDto && this.details.liveMonitoringDto.jobEtaResponseDTO 
                && this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList && this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList){
                let list = this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList;
                bounds.push([this.details.jobLat,this.details.jobLng]);
              }
              if(this.userLatLngListTemp && !isEmpty(this.userLatLngListTemp))
                 bounds.push(this.userLatLngListTemp);
              if(this.isEmbed){
                this.map.fitBounds(bounds, {paddingBottomRight: [20, 20],paddingTopLeft:[90,75]})
              //  this.updateView(bounds);
              } else {
                this.map.fitBounds(bounds,{paddingBottomRight: [20, 40],paddingTopLeft:[90,90]});
              }
          }
      }
        
     async plotPath(timeValue,isForward, handleBackTracklogs) {
          let trackBaseDTOList = this.MAIN_QUEUE[0].trackBaseDTOList;
          let jobEtaResponseDTO = this.MAIN_QUEUE[0].jobEtaResponseDTO;
          if (trackBaseDTOList && trackBaseDTOList.length > 0) {
              this.IS_QUEUE_RUN = true;
              var mapPoints = [];
              var time = [];
              if(!this.lastPlotted.latitude){
                this.lastPlotted = trackBaseDTOList[0];
              }
              mapPoints.push([this.lastPlotted.latitude,this.lastPlotted.longitude]);
              // this.map.fitBounds(mapPoints, { padding: [20, 20,10,30] });
                for(var i in trackBaseDTOList){
                      var trackLog = trackBaseDTOList[i];
                      if(this.lastPlotted.latitude == this.details.feLat && this.lastPlotted.longitude == this.details.feLng ){
                        var trackLog = trackBaseDTOList[0];
                        var angle = await this.findRotationAngle(this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList[0].lat,this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList[0].lng, this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList[1].lat,this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList[1].lng );
                        this.lastPlotted = trackLog;
                        mapPoints.push([trackLog.latitude,trackLog.longitude]);
                      }
                      else{
                        /// lastPloted -> current location fe for first time
                        var angle = await this.findRotationAngle(this.lastPlotted.latitude,this.lastPlotted.longitude,trackLog.latitude,trackLog.longitude);
                        this.lastPlotted = trackLog;
                        // calCulate angle ->
                        mapPoints.push([trackLog.latitude,trackLog.longitude]);
                      }
                        time.push(timeValue);
                        this.recalculateZoom(trackLog,time.length,timeValue);
                        this.isMovingEnd  = false;
                }
              if(this.map){
                this.createMarkerAndPolyLine(mapPoints,time,jobEtaResponseDTO,isForward, handleBackTracklogs);
              }
              
          }
          this.MAIN_QUEUE.splice(0,1);
      }
    
      createMarkerAndPolyLine(mapPoints,time,jobEtaResponseDTO,isForward, handleBackTracklogs){ 
          if(handleBackTracklogs) { // remove log once testing is completed
            console.log("moving marker forward for back trackLog for mapPoints=" + mapPoints + " and time=" + time);
          }
          if(this.myMovingMarker) {
            this.map.removeLayer(this.myMovingMarker);
          }
          this.myMovingMarker = L.Marker.movingMarker(mapPoints,time, {autostart: true,icon:this.feIcon,  rotationAngle: this.rotationAngle}).addTo(this.map);
          this.myMovingMarker.on('move',function(){
            if(this.feMarker){
              this.map.removeLayer(this.feMarker);
            }
          }.bind(this));
          this.myMovingMarker.on('end',function(){
                  this.map.removeLayer(this.myMovingMarker)
                  if(this.trackLogSource && this.trackLogSource=="GTS" && details.trackingType!="api"){
                      this.drawpathForGTS(this.details);
                  } else {
                    this.renderPath(jobEtaResponseDTO)
                    setTimeout(function() {
                        if(this.MAIN_QUEUE && this.MAIN_QUEUE.length > 0){
                          this.plotPath();
                        }  else if(this.trackUserLatLngList && this.trackUserLatLngList.length>0){
                          this.IS_QUEUE_RUN = false
                          this.setTrackPathData(isForward, false, false, handleBackTracklogs);
                        } else {
                            this.isMovingEnd = true;
                            this.IS_QUEUE_RUN = false
                            this.isFakePathRunning = false;
                            if(!handleBackTracklogs) {
                              // do not descrease ETA for BackTrackLogs
                              this.calCulateEtaOnEnd();
                            }
                            this.fakeCountMovement = 0;
                            this.updateAverageETA = true;
                            this.isActualPath  = false;
                        }
                      }.bind(this),200);
                  }
          }.bind(this));
      }
    
      renderPath(jobEtaResponseDTO){
        if(jobEtaResponseDTO && jobEtaResponseDTO.userRouteDtoList){
          for(var i in jobEtaResponseDTO.userRouteDtoList) {
            let userRouteDto = jobEtaResponseDTO.userRouteDtoList[i];
            if(userRouteDto && userRouteDto.latLngList){
              if(this.firstpolyline){
                  this.map.removeLayer(this.firstpolyline)
                }
              //  if(this.isEmbed){
                 this.userLatLngListTemp = userRouteDto.latLngList;
                // this.map.fitBounds(userRouteDto.latLngList, {paddingBottomRight: [20, 50],paddingTopLeft:[90,75]});
              //  }
              const customColor = this.details.customerInteractionThemeSettingsDTO.trackingPathColor;
              let routeColor = window.localStorage.getItem('isHerePoweredAccount') === 'true' ? 'blue' : '#05e862' ;
              routeColor = !isEmpty(customColor) ? customColor : routeColor;
              const movingPolyLine = new L.Polyline(userRouteDto.latLngList, {
                color: routeColor,
                weight: 4,
                opacity: 0.4,
                smoothFactor: 3,
                className: "leaflet-polyline-styling"
              });
              const staticPolyline = new L.Polyline(userRouteDto.latLngList, {
                color: routeColor,
                weight: 4,
                opacity: 0.4,
                smoothFactor: 3,
              });
              this.firstpolyline = new L.LayerGroup([staticPolyline]);
              if(!this.checkForTrackingInaccuracy()) {
                this.firstpolyline.addLayer(movingPolyLine);
              }
              this.firstpolyline.addTo(this.map);
            }
            if(userRouteDto && userRouteDto.duration && userRouteDto.duration.text 
              && (this.updateEtaFromServer || this.showPrevJobETA)){
               this.eta = userRouteDto.duration.text;
               this.etaValue = userRouteDto.duration.value;
               this.initialETA = userRouteDto.duration.value;
               this.bindPopUp();
            }
            if(this.lastPlotted.latitude){
              this.feMarker = L.marker([this.lastPlotted.latitude,this.lastPlotted.longitude],{
                                            icon:this.feIcon,
                                            rotationAngle:this.rotationAngle             
                                          }).addTo(this.map);
            }
          }
        } else  if(this.lastPlotted.latitude) {
              if(this.details && this.details.jobLat) {
                var jobBound = new L.LatLng(this.details.jobLat, this.details.jobLng)
              }
              var feBound = new L.LatLng(this.lastPlotted.latitude, this.lastPlotted.longitude);
              this.feMarker = L.marker([this.lastPlotted.latitude,this.lastPlotted.longitude],{
                                                icon:this.feIcon,
                                                rotationAngle:this.rotationAngle

                                            }).addTo(this.map);
              this.setBoundMap(jobBound,feBound);
        }
      }

      bindPopUp(text){
        if(text){
          let customPopup;
          if(text.length<9 && this.jobLocationMarker){
            customPopup = "<span class='fs12'><b>"+text+"</b></span>";
            let customOptions =
            {
              'maxWidth': '400',
              'width': '200',
              'className' : 'popupSmallCustom',
              'autoPan':true
              }
              this.details["jobLocationMarker"] = this.jobLocationMarker;
              this.details.jobLocationMarker.bindPopup(customPopup,customOptions,{closeOnClick:false}).addTo(this.map).openPopup();
              this.details.jobLocationMarker.off('click');
             
          } else {
             customPopup = "<b>"+text+"</b>";
             let customOptions =
             {
               'maxWidth': '400',
               'width': '200',
               'className' : 'popupCustom',
               'autoPan':true
               }
               this.details["jobLocationMarker"] = this.jobLocationMarker;
               this.details.jobLocationMarker.bindPopup(customPopup,customOptions,{closeOnClick:false}).addTo(this.map).openPopup();
               this.details.jobLocationMarker.off('click');
          }
        }
        if(!isEmpty(this.eta) && this.jobLocationMarker && (this.isEmbed || window.location.href.indexOf('&embed=true')!=-1) ){
          let customPopup;
          if(this.eta.length<9){
            customPopup = "<span class='fs12'><b>"+this.eta+" away</b></span>";
            let customOptions =
            {
              'maxWidth': '400',
              'width': '200',
              'className' : 'popupSmallCustom',
              'autoPan':this.isReCenterAllowed?true:false
              }
              this.jobLocationMarker.bindPopup(customPopup,customOptions,{closeOnClick:false}).addTo(this.map).openPopup();
              this.jobLocationMarker.off('click');
             
          } else {
             customPopup = "<b>"+this.eta+" away</b>";
             let customOptions =
             {
               'maxWidth': '400',
               'width': '200',
               'className' : 'popupCustom',
               'autoPan':this.isReCenterAllowed?true:false
               }
              this.jobLocationMarker.bindPopup(customPopup,customOptions,{closeOnClick:false}).addTo(this.map).openPopup();
              this.jobLocationMarker.off('click');
          }
        }
        this.resizeMap();
      }

      resizeMap(){
        if(this.map){
          this.map._onResize();
          this.map.invalidateSize();
         }
      }

//  <===========================  All functions related to fake movements and ETA -Start ===============================================>
updateLocalDateTime(tracklogs){
  if(!isEmpty(tracklogs[tracklogs.length-1].type) && this.dateTime){
      if(this.dateTime!=tracklogs[tracklogs.length-1].type){
            this.dateTime = tracklogs[tracklogs.length-1].type
            if(this.details && this.details.liveMonitoringDto){
              this.details.liveMonitoringDto.lastUpdatedAt = this.dateTime ;
            }
      } else {
        let tempDate = cloneDeep(this.dateTime);
        let time = tempDate.substring(17,tempDate.length);
        time = parseInt(time);
        time = time>58?time:time+1;
        this.dateTime = this.dateTime.substring(0,this.dateTime.length-2)+time;
        if(this.details && this.details.liveMonitoringDto){
          this.details.liveMonitoringDto.lastUpdatedAt = this.dateTime ;
        }
      }
  }
}


checkForTrackingInaccuracy = () => {

  const messageDiv = document.getElementById(trackingInaccuracyConstants.TRACKING_INACCURACY_MESSAGE_ID);
  if(!messageDiv) {
    return false;
  }

  if(isEmpty(this.details) || isEmpty(this.details.timeZone) || isEmpty(this.dateTime)) {
    messageDiv.style.visibility = 'hidden';
    return false;
  } 

  try {
    const lastTracklogMoment = moment.tz(this.dateTime, 'YYYY-MM-DD HH:mm:ss', true, this.details.timeZone);
    if(!lastTracklogMoment || !lastTracklogMoment.isValid()) {
      console.error("lastTrackLog moment is not valid");
      messageDiv.style.visibility = 'hidden';
      return false;
    }

    const lastTrackLogTimeStamp = lastTracklogMoment.unix();
    const currentTimeStamp = moment.tz(this.details.timeZone).unix();
    const diffInSec = Math.abs(currentTimeStamp - lastTrackLogTimeStamp);
    if( diffInSec > trackingInaccuracyConstants.INACCURACY_THRESHOLD_SECONDS) {
      messageDiv.style.visibility = 'visible';
      return true;
    } else {
      messageDiv.style.visibility = 'hidden';
      return false;
    }
  } catch(err) {
    console.log("Error parsing the timezone", err);
    messageDiv.style.visibility = 'hidden';
  }
  return false;
}

async startFakeMovement (details,url){
  console.log("Inside enhnace live trcaking servuce ", details);
  if(this.showPrevJobETA){
    let response = await restClientService.getTrackLogByUserId(details.userId,url,this.dateTime,this.details.jobId, details.trackLogSource);
    // returning since we do not perform any computation if we need to show previousJobsInfo
    return {
      showPreviousJobsInfo: true,
      trackLogResponse: response
    }
  }
  this.checkForTrackingInaccuracy();
  if(this.details && this.details.isJobToRemove!=undefined && this.details.isJobToRemove){
    this.details.alreadyRefresh=this.details.alreadyRefresh!=undefined?this.details.alreadyRefresh+1:0;
    return (this.details.alreadyRefresh && this.details.alreadyRefresh>0)?"CLOSE":"REFRESH";
  }
   try{
    if(details && details.showTracking && details.jobId  &&  !this.isFakePathRunning &&  !this.IS_QUEUE_RUN && (details.userId || details.sourceId)){
      if(details.trackLogSource!="GTS" && !isEmpty(details.sourceId) && this.fakeCountMovement == 0){
        this.fakeCountMovement == 1;   
        this.dateTime = new Date().toDateString();
        let liveMonitoringDto = await this.getTrackLogsFromServer(this.details.jobId,details.trackLogSource,this.details.userId,this.url,details.sourceId,this.details.pmId, this.details.carrierCode);
            if(liveMonitoringDto && !isEmpty(liveMonitoringDto)){
              this.details.liveMonitoringDto = liveMonitoringDto;
                this.getTrackData(liveMonitoringDto);
            }
            this.fakeCountMovement == 0;
      }
      else if(this.fakeCountMovement == 0 && !this.isActualPath){
        this.fakeCountMovement = 1;
        this.updateEtaFromServer = false;
        this.isActualPath = false;
         if( this.nearBy || (this.etaValue && this.etaValue>60 && (this.etaValue <= this.initialETA/2 ))){
           console.log(" nearby",this.nearBy);
          await this.getEtaFromServer();
         } else {
          let tracklogs;
          let response = await restClientService.postTrackLogsByUserId(details.userId,url,this.dateTime,this.details.jobId,this.details.trackLogSource, this.cacheData, this.details.sourceId, this.details.carrierCode,this.details.trackingType);
          if( (this.retryFELocation || this.initialPath==undefined || this.initialPath.length<1) && 
          (response && !response.isJobToRemove && response.trackBaseDTOList && response.trackBaseDTOList.length>0)){
            localStorage.clear(); 
            this.retryFELocation =false;
            window.location.reload(false);
            //  return "REFRESH"; 
          }
          if(isEmpty(this.cacheData) && (isEmpty(response) || isEmpty(response.trackBaseDTOList))) {
            this.fakeCountMovement = 0;
            return; // predictive movement only after first actual latlongs
          }
          if((this.dateTime==undefined || isEmpty(this.dateTime)) && response && response.lastCalledAt){
              var myDate = new Date((response.lastCalledAt));
              myDate.setMinutes(myDate.getMinutes()-10);
              var options = { hour12: false };
              this.dateTime= cloneDeep(myDate.toLocaleString('af-NA', options));
          }
          if(response && response.isJobToRemove!=undefined){
               if(response.isJobToRemove){
                  this.details.isJobToRemove=true;
                  return
               } 
                 tracklogs= response.trackBaseDTOList;
               
           }else if(response!=undefined) {
             trackLog=response;
           }
          if(tracklogs && !isEmpty(tracklogs)){
            // check if the same LatLng again 
              if(
                (!isEmpty(this.lastPlotted) && this.calculateDistanceJobFromFe(this.lastPlotted.latitude, this.lastPlotted.longitude, tracklogs[tracklogs.length-1].latitude, tracklogs[tracklogs.length-1].longitude) < predictMovementConstants.SAME_LOCATION_TOLERANCE_IN_METERS) ||
                (!isEmpty(this.actualLastLng) && this.calculateDistanceJobFromFe(this.actualLastLng.latitude, this.actualLastLng.longitude, tracklogs[tracklogs.length-1].latitude, tracklogs[tracklogs.length-1].longitude) < predictMovementConstants.SAME_LOCATION_TOLERANCE_IN_METERS) ||
                (!isEmpty(this.details) && this.details.feLat && this.calculateDistanceJobFromFe(this.details.feLat, this.details.feLng, tracklogs[tracklogs.length-1].latitude, tracklogs[tracklogs.length-1].longitude) < predictMovementConstants.SAME_LOCATION_TOLERANCE_IN_METERS)
              ) {
                if(details && details.trackLogSource && details.trackLogSource=="FAREYE_MOBI_USER_SUMMARY"){
                  this.dateTime = !isEmpty(tracklogs[tracklogs.length-1].type)?tracklogs[tracklogs.length-1].type:this.dateTime;
                  this.updateLocalDateTime(tracklogs);
                  this.startFakePath(predictMovementConstants.STANDARD_MOVEMENT_SEGMENTS,false,true)
                } else {
                  this.updateLocalDateTime(tracklogs);
                  this.updateLocalVariable();
                }
              } else {
                
                let index =  await this.getNearestIndexOfPath(tracklogs[tracklogs.length-1].latitude,tracklogs[tracklogs.length-1].longitude,this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList);
                index = await this.checkIndexValidation(index,this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList);
                if(index!=0){
                  this.actualLastLng = {
                    "latitude" : tracklogs[tracklogs.length-1].latitude,
                    "longitude": tracklogs[tracklogs.length-1].longitude
                  }

                  this.updatedUserTracklogList = tracklogs;
                }
                if(index==-1){
                  // check if lat lng are of behind of FE 
                  let path = this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList.length;
                  if(this.initialPath && this.initialPath.length>0 && path < this.initialPath.length){
                    const predictiveMarginInfo = this.getPredictiveLocationMarginInfo();
                    if(predictiveMarginInfo.distance && (predictiveMarginInfo.distance < 0 || predictiveMarginInfo.distance > predictMovementConstants.BACK_LOCATION_TOLERANCE)) {
                      await this.getEtaFromServer();
                      return;
                    }
                     index = await this.getNearestIndexOfPath(tracklogs[tracklogs.length-1].latitude, tracklogs[tracklogs.length-1].longitude, this.initialPath);
                     if(index!=-1){
                      this.updateLocalDateTime(tracklogs)
                      this.startFakePath(predictMovementConstants.BEHIND_LATLONG_MOVEMENT_SEGMENTS, false, false, false, false, false, true);
                     } else {
                        await this.getEtaFromServer();
                        return;
                     }
                  } else {
                     await this.getEtaFromServer();
                     return;
                  }
              } else {
                if(index==0){
                  await this.getEtaFromServer();
                  return;
                } else {
                  this.updateLocalDateTime(tracklogs)
                  // this.dateTime = tracklogs[tracklogs.length-1] && tracklogs[tracklogs.length-1].type && !isEmpty(tracklogs[tracklogs.length-1].type)?tracklogs[tracklogs.length-1].type:this.dateTime;
                  this.startFakePath(index,false,false,true)
                }
              }
            }
          } else {
              this.startFakePath(predictMovementConstants.STANDARD_MOVEMENT_SEGMENTS,false,true)
          }
         }
        
         this.updateCache(details);
         return this.eta;
        } else {
          !isEmpty(this.cacheData) && this.updateCache(details);
          return this.eta;
        }
      }
      
   }catch(err){
     console.log("error",err);
     this.fakeCountMovement = 0;
   }
}

/**
 * calculates distance and path between the actual FE location and predictive location
 * @returns object containg the distance in meters and the path between them, distance is -1 if the points dont lie on the initial route path
 */
getPredictiveLocationMarginInfo = () => {
  const result = {
    distance: 0,
    marginPath: []
  }
  if(isEmpty(this.lastPlotted) || isEmpty(this.initialPath) || isEmpty(this.updatedUserTracklogList)) {
    return result;
  }
  const lastTrackLog = this.updatedUserTracklogList[this.updatedUserTracklogList.length - 1];
  const index1 = this.getNearestIndexOfPath(this.lastPlotted.latitude, this.lastPlotted.longitude, this.initialPath);
  const index2 = this.getNearestIndexOfPath(lastTrackLog.latitude, lastTrackLog.longitude, this.initialPath);
  if(index1 < 0 || index2 < 0) {
    result.distance = -1;
    return result;
  }

  let distance = 0;
  let path = [];
  if(index1 < index2) {
    path = this.initialPath.slice(index1, index2);
    distance = this.calculateTotalDistanceJobFromFe(path);
  } else {
    path = this.initialPath.slice(index2, index1);
    distance = this.calculateTotalDistanceJobFromFe(path);
  }
  return {
    distance: distance,
    marginPath: path
  };
}

/**
 * calculates speed of a segment based on tha past movement of the driver
 * @param {List} userTrackLogList : constains the list of trackLogs with the speed of FE
 * @param {Number} segmentNumber : spped of segment to return
 * @returns speed in m/s
 */
calculateSpeedForBackTrackLogs = (userTrackLogList, segmentNumber) => {
  if(isEmpty(userTrackLogList) || segmentNumber < 0) {
    return (predictMovementConstants.STANDARD_MOVEMENT_SPEED_KMPH/2) * (5/18);
  }
  

  const totalSegments = Math.ceil(userTrackLogList.length / predictMovementConstants.BEHIND_LATLONG_MOVEMENT_SEGMENTS);
  segmentNumber = segmentNumber % totalSegments;

  const startIdx = segmentNumber * predictMovementConstants.BEHIND_LATLONG_MOVEMENT_SEGMENTS;
  const endIdx = Math.min((startIdx + predictMovementConstants.BEHIND_LATLONG_MOVEMENT_SEGMENTS), userTrackLogList.length);
  let speedInKmph = 0;
  for(let i = startIdx; i < endIdx; i++) {
    const trackLog = userTrackLogList[i];
    if(isEmpty(trackLog) || Number(trackLog.speed) < 5 || Number(trackLog.speed) > 100) {
      continue;
    }

    speedInKmph = speedInKmph + (Number(trackLog.speed));
  }

  if(speedInKmph < 5 || speedInKmph > predictMovementConstants.STANDARD_MOVEMENT_SPEED_KMPH) {
    speedInKmph = predictMovementConstants.STANDARD_MOVEMENT_SPEED_KMPH;
  }

  return (speedInKmph / 2) * (5/18);

}

/**
 * calculates the time that would be taken by FE to reach fake location from actual location acc to its past speed per segment
 * @returns time in seconds
 */
getEffectiveTimeForBackTrackLogMovement = (destinationTrackLog) => {
  const predictiveLocationMarginInfo = this.getPredictiveLocationMarginInfo();
  let pathInConsideration = [];
  if(!isEmpty(predictiveLocationMarginInfo) && !isEmpty(predictiveLocationMarginInfo.marginPath) && Array.isArray(predictiveLocationMarginInfo.marginPath)) {
    pathInConsideration = [...predictiveLocationMarginInfo.marginPath];
  }

  if(!isEmpty(this.trackUserLatLngList) && Array.isArray(this.trackUserLatLngList)) {
    pathInConsideration = [...pathInConsideration, ...this.trackUserLatLngList];
  }

  if(pathInConsideration.length < 2) {
    return predictMovementConstants.BEHIND_LATLONG_MOVEMENT_STANDARD_TIME_IN_SEC;
  }

  if(isEmpty(destinationTrackLog) || isEmpty(this.lastPlotted)) {
    return predictMovementConstants.BEHIND_LATLONG_MOVEMENT_STANDARD_TIME_IN_SEC;
  }

  let effectiveTime = 0;
  for(let i = 1; i < pathInConsideration.length; i++) {

    const trackLog1 = pathInConsideration[i-1];
    const latitude1 = trackLog1.lat || trackLog1.latitude || 0; // this is added to handle different keys we are using for latitude / longitude
    const longitude1 = trackLog1.lng || trackLog1.longitude || 0; // lat / lng is given preference as this one is comes from google

    const tracklog2 = pathInConsideration[i];
    const latitude2 = tracklog2.lat || tracklog2.latitude || 0;
    const longitude2 = tracklog2.lng || tracklog2.longitude || 0;

    if(!latitude1 > 0 || !longitude1 > 0 || !latitude2 > 0 || !longitude2 > 0) {
      continue;
    }
    const dis = this.calculateDistanceJobFromFe(latitude1, longitude1, latitude2, longitude2);
    const speed = this.calculateSpeedForBackTrackLogs(this.updatedUserTracklogList, i);
    effectiveTime = effectiveTime + (dis/speed);
  }

  const latitude1 = this.lastPlotted.lat || this.lastPlotted.latitude || 0;
  const longitude1 = this.lastPlotted.lng || this.lastPlotted.longitude || 0;
  const latitude2 = destinationTrackLog.lat || destinationTrackLog.latitude || 0;
  const longitude2 = destinationTrackLog.lng || destinationTrackLog.longitude || 0;

  const distanceToTravel = this.calculateDistanceJobFromFe(latitude1, longitude1, latitude2, longitude2);

  return (distanceToTravel * effectiveTime) / predictiveLocationMarginInfo.distance;
}

async calCulateEtaOnEnd(){
  if(this.updateETA && !this.updateEtaFromServer){
    let dis = await this.calculateDistanceJobFromFe(this.lastPlotted.latitude,this.lastPlotted.longitude,this.details.jobLat,this.details.jobLng);
    if(dis){
        let travelTime = this.totalDistance/(45*(5/18));
        if(travelTime<10){
          this.etaValue = this.etaValue+(10);         // to compensate  wait time of next hit
        }
        this.totalDistance = 0; 
        let time = this.updateAverageETA?-1:dis/(45*(5/18));
        this.setEta(time);
        this.fakeCountMovement = 0;
     }
  }  else if(this.updateAverageETA){
        this.setEta(-1);
  }
  
}

async handleLocationException(){
  try{
    let tracklogs;
    let response = await restClientService.postTrackLogsByUserId(this.details.userId,this.url,this.dateTime,this.details.jobId, this.details.tracklogSource, this.cacheData);
      if(response && response.isJobToRemove!=undefined){
        if(response.isJobToRemove){
          this.details.isJobToRemove=true;
          return
        } 
          tracklogs= response.trackBaseDTOList;
        
    }else if(response!=undefined) {
      trackLog=response;
    }
    if(tracklogs && !isEmpty(tracklogs)){
      if((this.lastPlotted && this.lastPlotted.latitude==tracklogs[tracklogs.length-1].latitude && this.lastPlotted.longitude==tracklogs[tracklogs.length-1].longitude
        ) || (this.details.feLat && this.details.feLat==tracklogs[tracklogs.length-1].latitude && this.details.feLng == tracklogs[tracklogs.length-1].longitude)
        || (this.actualLastLng && this.actualLastLng.latitude == tracklogs[tracklogs.length-1].latitude && this.actualLastLng.longitude == tracklogs[tracklogs.length-1].longitude)){
        this.updateLocalVariable();
        return this.eta;
      }
      this.actualLastLng = {
        "latitude" : tracklogs[tracklogs.length-1].latitude,
        "longitude": tracklogs[tracklogs.length-1].longitude
      }
      this.startFakePath(1,false);
      this.updateLocalDateTime(tracklogs);
    }
    
  }catch(err){
     console.log("err",err);
  }
    
}


async getEtaFromServer(){
  this.isActualPath = true;
  let liveMonitoringDto = await this.getTrackLogsFromServer(this.details.jobId,this.dateTime,this.details.userId,this.url, this.details.pmId, this.details.carrierCode, this.details,trackingType );
    if(liveMonitoringDto && liveMonitoringDto.isJobToRemove){
        this.details.isJobToRemove=true;
        return;
    }  
  // if tracklogs are not empty
     if(liveMonitoringDto && liveMonitoringDto.trackBaseDTOList && liveMonitoringDto.trackBaseDTOList.length>0){
      this.isReCenterAllowed = true;
        let length = liveMonitoringDto.trackBaseDTOList.length;   
       let tracklogs = [{
            "latitude":liveMonitoringDto.trackBaseDTOList[length-1].latitude,
            "longitude":liveMonitoringDto.trackBaseDTOList[length-1].longitude
          }];
          if((this.lastPlotted && this.lastPlotted.latitude==tracklogs[tracklogs.length-1].latitude && this.lastPlotted.longitude==tracklogs[tracklogs.length-1].longitude
            ) || (this.details.feLat && this.details.feLat==tracklogs[tracklogs.length-1].latitude && this.details.feLng == tracklogs[tracklogs.length-1].longitude)
            || (this.actualLastLng && this.actualLastLng.latitude == tracklogs[tracklogs.length-1].latitude && this.actualLastLng.longitude == tracklogs[tracklogs.length-1].longitude)){
            this.updateLocalVariable();
            return this.eta;
          }
          this.actualLastLng = {
            "latitude" : tracklogs[tracklogs.length-1].latitude,
            "longitude": tracklogs[tracklogs.length-1].longitude
          }
     this.dateTime = (liveMonitoringDto && liveMonitoringDto.lastUpdatedAt)? liveMonitoringDto.lastUpdatedAt:this.dateTime; 
      this.details.liveMonitoringDto.lastUpdatedAt = this.dateTime ;
     let index =  await this.getNearestIndexOfPath(tracklogs[tracklogs.length-1].latitude,tracklogs[tracklogs.length-1].longitude,this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList);
     let path = this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList.length;
     if(index==-1){
         // check if lat lng are of behind of FE 
         if(this.initialPath && this.initialPath.length>0 && path < this.initialPath.length){
           let lat = tracklogs[tracklogs.length-1].latitude;
           let lng = tracklogs[tracklogs.length-1].longitude;
           let latLngs = await this.findMidPoint(this.lastPlotted,lat,lng);
            index = await this.getNearestIndexOfPath(latLngs[latLngs.length-1].lat,latLngs[latLngs.length-1].lng,this.initialPath);
            if(index!=-1){
              
                // update ETA(Average of both ETA)
                 this.time = 60;
                 let updatedEtaValue =  liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].duration.value;
                 this.etaValue = (this.etaValue+updatedEtaValue)/2;
                 this.initialETA = this.etaValue;
                 this.updateAverageETA =true;
                this.startFakePath(index,true,false,true);
            } else {
                 // update ETA direct and path like initially 
                 this.details.liveMonitoringDto = liveMonitoringDto;
                 this.updateEtaFromServer = true;
                 if(liveMonitoringDto.jobEtaResponseDTO && liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList && liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0] && liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList) {
                  this.initialPath = JSON.parse(JSON.stringify(liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList));
                 } 
                 this.startFakePath(predictMovementConstants.STANDARD_MOVEMENT_SEGMENTS,false,false,false,true,true);
                //  setTimeout(function(){
                //    this.fakeCountMovement = 0;
                //  }.bind(this),20000);
            }
         }  else {
            // update ETA direct and path like initially
            this.details.liveMonitoringDto = liveMonitoringDto;
            this.updateEtaFromServer = true;
            if(liveMonitoringDto.jobEtaResponseDTO && liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList && liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0] && liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList) {
             this.initialPath = JSON.parse(JSON.stringify(liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList));
            } 
            this.startFakePath(predictMovementConstants.STANDARD_MOVEMENT_SEGMENTS,false, false, false, true, true);
         }
       } else {  
        if(index==0){
          this.details.liveMonitoringDto = liveMonitoringDto;
          this.updateEtaFromServer = true;
          if(liveMonitoringDto.jobEtaResponseDTO && liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList && liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0] && liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList) {
           this.initialPath = JSON.parse(JSON.stringify(liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList));
          } 
          this.startFakePath(predictMovementConstants.STANDARD_MOVEMENT_SEGMENTS,false);
        } else {
          let updatedEtaValue =  liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].duration.value;
          let duration_text =    liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].duration.text;
          if(duration_text && duration_text =="1 min"){
           if(liveMonitoringDto.jobEtaResponseDTO && liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList && liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0] 
               && liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList && path.length+2<liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList.length) {
             this.initialPath = JSON.parse(JSON.stringify(liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList));
            } 
           this.details.liveMonitoringDto = liveMonitoringDto;
           this.updateEtaFromServer = true;
           this.startFakePath(predictMovementConstants.STANDARD_MOVEMENT_SEGMENTS,false,false,false,true,true);
          } else {
           this.etaValue = (this.etaValue+updatedEtaValue)/2;
           this.initialETA = this.etaValue;
           this.time = 61;
           this.updateAverageETA =true;
           this.startFakePath(index,false,false,true,true);
          }
        }
       }
     } else if(liveMonitoringDto){
       this.handleLocationException();
       this.updateLocalVariable();
      //  this.startFakePath(0,false,false,true);
     } else {
       this.fakeCountMovement = 0;
     }
     this.updateCacheForDataFromGoogle(this.details);
     setTimeout(function(){
       this.isActualPath = false;
     }.bind(this),20000)
}

updateCacheForDataFromGoogle = (details) => {
  if(isEmpty(details) || isEmpty(details.liveMonitoringDto)) {
    return;
  }

  const obj = {
    "lastPlotted": this.lastPlotted,
    "liveMonitoringDto": this.details.liveMonitoringDto,
    "initialETA": this.initialETA,
    "eta": this.eta,
    "dateTime": this.dateTime,
    "etaValue": this.etaValue,
    "updateETA": this.updateETA,
    "userId": details.userId
  };

  this.cacheData = {...obj};
}

getNearestIndexOfPath(lat,lng,path){
  let index = -1,minDistance = -1;
  for(let i=0;i<path.length;i++){
       let distance = this.calculateDistanceJobFromFe(lat,lng,path[i].lat,path[i].lng);
        if(minDistance==-1){
          index = i;
          minDistance = distance
        } else if(minDistance>distance){
          index = i;
          minDistance = distance;
        } else if(minDistance<10){
          index = i;
          break;
        }
  }
  if(minDistance<=100 && index!=-1){
    return index;
  }
  return -1;
}







async startFakePath(index,isPathUpdated,isFake,isForward,isEtaUpdated,isDeviate, handleBackTracklogs){
    this.isFakePathRunning = true;
    this.updateETA = true;
    try{
      if(isPathUpdated){
        this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList = JSON.parse(JSON.stringify(this.initialPath));
      }
      let actualLatLngList= this.details.liveMonitoringDto? this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList:[];
      this.trackUserLatLngList = isPathUpdated?actualLatLngList[0].latLngList.splice(index,1):actualLatLngList[0].latLngList.slice(0,index==0?index+1:index) // first lat long

      if(isFake){
       let updatedIndex = -1;
       for(let i=0;i<this.trackUserLatLngList.length;i++){
        let dis = await this.calculateDistanceJobFromFe(this.trackUserLatLngList[i].lat,this.trackUserLatLngList[i].lng,this.details.jobLat,this.details.jobLng);
        let distanceq = await this.calculateTotalDistanceJobFromFe(cloneDeep(actualLatLngList[0].latLngList));
        if(dis<=300){
            if((dis<=100 && distanceq<300) || (dis>100 && dis<=200 && distanceq<250)||(dis>200 && distanceq<250)){
              updatedIndex = i;
              break;
            }
            
           }
       }
       if(updatedIndex!=-1){
          this.nearBy = true;
         if(updatedIndex==0){
          // this.trackUserLatLngList = [];
          this.isFakePathRunning = false;
          // return;
         }
         index = index-(this.trackUserLatLngList.length-updatedIndex);
        this.trackUserLatLngList = JSON.parse(JSON.stringify(this.trackUserLatLngList.slice(0,updatedIndex==0?1:updatedIndex)))
 
      }
   } else if(index==0){
    this.trackUserLatLngList = cloneDeep(actualLatLngList[0].latLngList)
   }
      const lastLatLng = JSON.parse(JSON.stringify(this.lastPlotted))
      this.lastPlotted = lastLatLng;
      // let list = this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList.slice(index==0?1:index);
      // this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList = list;
       this.setTrackPathData(isForward,isEtaUpdated,isDeviate, handleBackTracklogs);
       }catch(err){
         this.fakeCountMovement=0;
         this.isFakePathRunning =false;
         console.log("err",err);
       }
}

async setTrackPathData(isForward,isEtaUpdated,isDeviate, handleBackTracklogs){
  let trackUserLatLng = this.trackUserLatLngList.splice(0,1);
  if(!trackUserLatLng || !trackUserLatLng[0]){
     this.isFakePathRunning = false;
     this.fakeCountMovement = 0;
     this.updateAverageETA = true;
     this.isActualPath  = false;

     return;
  }
  let tempLatLng = [];
  tempLatLng.push({"latitude":trackUserLatLng[0].lat,"longitude":trackUserLatLng[0].lng})
  this.details.liveMonitoringDto.trackBaseDTOList = tempLatLng;
  let index = await this.getNearestIndexOfPath(tempLatLng[0].latitude,tempLatLng[0].longitude,this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList);
  let list = this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList.slice(index<=0?1:index);
  this.details.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList = list;
   // check here distance b/w FE current location and upcoming location
   let time = isDeviate?0.1/1000:1;
   if(!isEtaUpdated){
    let distance = await this.calculateDistanceJobFromFe(tempLatLng[0].latitude,tempLatLng[0].longitude,this.lastPlotted.latitude,this.lastPlotted.longitude);
    time = distance/(predictMovementConstants.STANDARD_MOVEMENT_SPEED_KMPH * (5/18));
    this.time = this.time+time;
    this.totalDistance = this.totalDistance+distance;
   }

  if(handleBackTracklogs) {
    time = this.getEffectiveTimeForBackTrackLogMovement(trackUserLatLng[0]);
  }
   
  //  await this.setEta(time);
  await this.getTrackData(this.details.liveMonitoringDto,isForward?300:time*1000,isForward, handleBackTracklogs);
}

getStandardTravelTimeBetweenLocation = (latitude1, longitude1, latitude2, longitude2) => {

  if(!this.initialPath ||!latitude1 || !longitude1 || !latitude2 || !longitude2) {
    return 0;
  }
  const index1 = this.getNearestIndexOfPath(latitude1, longitude1, this.initialPath);
  const index2 = this.getNearestIndexOfPath(latitude2, longitude2, this.initialPath);
  if(index1 < 0 || index2 < 0) {
    console.log("indices cannot be calculated");
    return 0;
  }
  
  let distanceTravelled = 0;

  if(index1 < index2) {
    const path = this.initialPath.slice(index1, index2);
    distanceTravelled = this.calculateTotalDistanceJobFromFe(path);
  } else {
    const path = this.initialPath.slice(index2, index1);
    distanceTravelled = this.calculateTotalDistanceJobFromFe(path);
  }

  if(!distanceTravelled || distanceTravelled < 1) {
    console.log("distanceTravelled is not accurate");
    return 0;
  }

  return distanceTravelled / (predictMovementConstants.STANDARD_MOVEMENT_SPEED_KMPH * (5/18));

}

setEta(time){
  this.time = this.time+10;
    if(this.time>59){
           this.etaValue = this.etaValue-(this.time-(this.time%60));
           this.etaValue = (time && time!=-1)?(Math.ceil(time)+this.etaValue)/2:this.etaValue;
           this.etaValue = this.etaValue;
           let seconds = Math.ceil(this.etaValue);
           seconds = seconds<60?60:seconds;
           if(seconds<3600){
            var min = Math.floor(seconds/60); 
              this.eta = min+" mins";
           } else {
            var hr = Math.floor(seconds/3600); 
            var x = seconds%3600;
            var min = Math.floor(x/60); 
            min = min<1?1:min;
            this.eta = hr+"hr "+min+"mins";      
           }
           this.time = this.time%60;
           if((this.isEmbed || window.location.href.indexOf('&embed=true')!=-1)){
            this.bindPopUp();
          }
         
    }
    
}

updateLocalVariable(){
     this.isFakePathRunning = false;
     this.fakeCountMovement = 0;
     this.updateAverageETA = true;
     this.isActualPath  = false;
}

handleTrackingWithCacheData = (trackingDetails) => {
  if(!isEmpty(this.cacheData)) {
    return cloneDeep(this.cacheData);
  }

  if(
    isEmpty(trackingDetails) ||
    isEmpty(trackingDetails.predictiveTrackingInfo)
  ) {
    return;
  }
  const predictiveTrackingInfo = {...trackingDetails.predictiveTrackingInfo};
  if(predictiveTrackingInfo.userId != trackingDetails.userId) {
    console.log("tracking cache is not od same user");
    return;
  }

  return predictiveTrackingInfo;
}

calculateTotalDistanceJobFromFe(actualLatLngList){
  let totalDistance =0;
  for(let i=0;i<actualLatLngList.length-1;i++){
    let lat1 = actualLatLngList[i].lat //lat1
    let lon1 = actualLatLngList[i].lng //lng1
    let lat2 = actualLatLngList[i+1].lat // lat2
    let lon2 = actualLatLngList[i+1].lng // lng2

    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p)/2 + 
      c(lat1 * p) * c(lat2 * p) * 
      (1 - c((lon2 - lon1) * p))/2;

    var d =  12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    var dInMeter= d * 1000;
    totalDistance=totalDistance+dInMeter;
  }
  
  return totalDistance;

}


findClosestLatLng(midPointLatLngList, userLatLngList){
      userLatLngList.map(async singleUserLatLng =>{
      let closestDistance= this.calculateDistancefromMidPoint(midPointLatLngList.latitude,midPointLatLngList.longitude,singleUserLatLng.lat, singleUserLatLng.lng )
      this.midPointDistance = closestDistance;
      })
      return  this.midPointDistance;
}

findMidPoint(lastPlotted, actualLatFE, actualLngFE){
      var lat1 = lastPlotted.latitude
      var lng1 = lastPlotted.longitude
      var lat2= actualLatFE
      var lng2 = actualLngFE
      lat1= this.getInRadian(lat1);
      lng1= this.getInRadian(lng1);
      lat2= this.getInRadian(lat2);
      lng2= this.getInRadian(lng2);
      var dlng = lng2 - lng1;
      var Bx = Math.cos(lat2) * Math.cos(dlng);
      var By = Math.cos(lat2) * Math.sin(dlng);
      var lat3 = Math.atan2( Math.sin(lat1)+Math.sin(lat2),
      Math.sqrt((Math.cos(lat1)+Bx)*(Math.cos(lat1)+Bx) + By*By ));
      var lng3 = lng1 + Math.atan2(By, (Math.cos(lat1) + Bx));
      var newLatLng =[];
      let lastLatLng ={
        lat:(lat3*180)/Math.PI,
        lng:(lng3*180)/Math.PI
      }
      newLatLng.push(lastLatLng); 
      return newLatLng;
}

// find Rotation Angle


async findRotationAngle(currentLat, currentLng, lat2,lon2){
  if(!currentLat || !currentLng || !lat2 || !lon2) {
    this.rotationAngle = 0;
    this.previousRotationAngle = 0;
    return 0;
  }

  var rotation = await this.calculateAngle(currentLat, currentLng,lat2,lon2)
  if(rotation == 0){
    this.rotationAngle = this.previousRotationAngle;
  } 
  else{
    this.rotationAngle = rotation;
    this.previousRotationAngle = rotation; 
  }
  this.rotationAngle =  this.rotationAngle - 90;
  return this.rotationAngle;
}
  //Calculate Distance From Current Lat Lng to Next Lat Lng 

      getDistanceFromLatLonInKm(currentLat, currentLng, nextRouteList){
            let lat1 = currentLat             //lat1
            let lon1 = currentLng             //lng1
            let lat2 = nextRouteList[0].lat   // lat2
            let lon2 = nextRouteList[0].lng   // lng2        
            // calculate  distance then find time
            var p = 0.017453292519943295;    // Math.PI / 180
            var c = Math.cos;
            var a = 0.5 - c((lat2 - lat1) * p)/2 + 
              c(lat1 * p) * c(lat2 * p) * 
              (1 - c((lon2 - lon1) * p))/2;

            var distance =  12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
            var distanceInMeter= distance * 1000;
            var time = this.speed/distanceInMeter
              var fixedTime = (time*1000).toFixed(0);
            this.timeInterval = fixedTime
            return this.timeInterval;

      }


    // Calculate Angle Method
      calculateAngle(prevLat,prevLong,newLat,newLong){
            // for reference check  http://www.igismap.com/formula-to-find-bearing-or-heading-angle-between-two-points-latitude-longitude/
            newLat = this.getInRadian(newLat);
            newLong = this.getInRadian(newLong);
            prevLat = this.getInRadian(prevLat);
            prevLong = this.getInRadian(prevLong);
            var dL = newLong - prevLong;
            var X = Math.cos(newLat) * Math.sin(dL);
            var Y = (Math.cos(prevLat) * Math.sin(newLat)) -(Math.sin(prevLat) * Math.cos(newLat) * Math.cos(dL));
            X = this.getInRadian(X);
            Y = this.getInRadian(Y);
            var angle = Math.atan2(X,Y);
            return angle * 180/Math.PI;  // return again back in degree;
    }
    // Calculate getInRadian Method

    getInRadian(angleInDegree){
      return angleInDegree * Math.PI/180;
    }


    calculateDistanceJobFromFe(currentLat, currentLng, jobLat, jobLng){
      if(!currentLat || !currentLng || !jobLat || !jobLng) {
        return 0;
      }

      let lat1 = currentLat //lat1
      let lon1 = currentLng //lng1
      let lat2 = jobLat // lat2
      let lon2 = jobLng // lng2

      var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p)/2 + 
      c(lat1 * p) * c(lat2 * p) * 
      (1 - c((lon2 - lon1) * p))/2;

    var d =  12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    var dInMeter= d * 1000;
      return dInMeter;

   }

     calculateDistancefromMidPoint(midPointLat, midPointLng, singleLat, singleLng){
            let lat1 = midPointLat//lat1
            let lon1 = midPointLng//lng1
            let lat2 = singleLat// lat2
            let lon2 = singleLng// lng2

            var p = 0.017453292519943295;    // Math.PI / 180
          var c = Math.cos;
          var a = 0.5 - c((lat2 - lat1) * p)/2 + 
            c(lat1 * p) * c(lat2 * p) * 
            (1 - c((lon2 - lon1) * p))/2;

          var d =  12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
          var dInMeter= d * 1000;            
          // here we will check distance is greater or lesser
          if(dInMeter >  this.previousDistance){
            return this.previousObj;
          } else if(dInMeter <  this.previousDistance || dInMeter ==  this.previousDistance ||  this.previousDistance == undefined){
            var obj1 = {};
            obj1[dInMeter]= {};
            obj1[dInMeter].lat=singleLat
            obj1[dInMeter].lng=singleLng            
          }
          this.previousDistance = dInMeter;
          this.previousObj = obj1
          return this.previousObj;
      }



     async checkIndexValidation(index,path){
       if(index==-1)return -1;
        let distance = await this.calculateDistanceJobFromFe(path[index].lat,path[index].lng,this.details.jobLat,this.details.jobLng);
        if(distance>300){
            return index;
          } else {
             while(distance<=300 && index>1){
                index = index-1;
               distance = await this.calculateDistanceJobFromFe(path[index].lat,path[index].lng,this.details.jobLat,this.details.jobLng);
             }
             if(distance <= 300){
               return -1;
             }
             return index;
          }
      }


  updateCache = (details) => {
    if(isEmpty(this.details.liveMonitoringDto)) {
      return;
    }

    if(this.lastPlotted.latitude){
      const obj= {
       "lastPlotted":this.lastPlotted,
       "liveMonitoringDto":this.details.liveMonitoringDto,
       "initialETA":this.initialETA,
       "eta":this.eta,
       "dateTime":this.dateTime,
       "etaValue":this.etaValue,
       "updateETA":this.updateETA,
       "updateEtaFromServer":this.updateEtaFromServer,
       "nearBy":this.nearBy,
       "userId":details.userId
      };
      this.cacheData = {...obj};
   };
  }



//  <===========================  All functions related to fake movements and ETA - END===============================================>

  clearZoom(){
    this.isReCenterAllowed = true;
    let bounds = [];
    if(this.lastPlotted && this.lastPlotted.latitude){
      bounds.push([this.lastPlotted.latitude,this.lastPlotted.longitude]);
    }
    if(this.details && this.details.jobLat){
      bounds.push([this.details.jobLat,this.details.jobLng]);
    }
    if(this.userLatLngListTemp && !isEmpty(this.userLatLngListTemp))
                 bounds.push(this.userLatLngListTemp);
    this.map.fitBounds(bounds, {paddingBottomRight: [20, 20],paddingTopLeft:[90,75]})
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  formatAccordingTo24Hour(date){
    var hours = date.getHours();
    var minutes = date.getMinutes();
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes;
    return strTime;
  }
// <==================================REST -API CALL Only =============================================>
// so can separate this in RestCall service in coming sprint task(may be)
      // tracklog with Google API
      async getTrackLogsFromServer(jobId,tracklogSource,userId,url,sourceId,pmId, carrierCode){
        const data = await restClientService.postTrackLogsFromServer(jobId, tracklogSource, userId, url, sourceId, this.dateTime, this.cacheData, pmId, carrierCode);
        if(data && data.length>0){
          if(tracklogSource && tracklogSource=="GTS" && details.trackingType!="api" && sourceId){
            this.dateTime = new Date().toDateString();

          }
        }
        return data;
      }
      async getTripEta(url,jobId,min,max,trackingEnableTime,browserTimeZone,timeFormat, jobTransactionId){
        const data = await restClientService.getTripEtaFromServer(url,jobId,browserTimeZone, jobTransactionId);
        let eta;
        if(browserTimeZone == true){
           eta = new Date((data).replace(/-/g, "/")+" UTC");
        }
        else{
           eta = new Date((data).replace(/-/g, "/"));
        }
        
        let minEta,maxEta;
        let currentDate = new Date();
        let actualEta,trackingEnableETA,enableEta;
        if(currentDate.getMonth()==eta.getMonth()){
          if(currentDate.getDate()==eta.getDate()){
            actualEta =  "Today  ";
          } else if(currentDate.getDate()+1===eta.getDate()){
            actualEta =  "Tomorrow"
          }else if(currentDate.getDate()<eta.getDate()){
            actualEta = " on "+eta.getDate()+" "+this.monthNames[eta.getMonth()]+" "
           }
       } else {
        actualEta = " on "+eta.getDate()+" "+this.monthNames[eta.getMonth()]+" "
       }
        if(min && min>0){
            let eta1  = cloneDeep(eta);
            eta1.setMinutes(eta1.getMinutes()-min);
            minEta = (timeFormat==CONSTANTS.TWENTYFOUR_HOUR_FORMAT)?this.formatAccordingTo24Hour(eta1):this.formatAMPM(eta1);
            // minEta = eta1.getHours()>12?(eta1.getHours()-12)+":"+(eta1.getMinutes()>9?eta1.getMinutes():"0"+eta1.getMinutes())+"PM":eta1.getHours()+":"+(eta1.getMinutes()>9?eta1.getMinutes():"0"+eta1.getMinutes())+"AM"
            if(trackingEnableTime){
              let enableEta1 = cloneDeep(eta1);
              trackingEnableETA = enableEta1.setMinutes(enableEta1.getMinutes()-trackingEnableTime);
              if(new Date().getTime()<trackingEnableETA){
                let diffTime = Math.abs(trackingEnableETA-(new Date().getTime()));
                enableEta = Math.ceil(diffTime/(1000*60))
              }
            }
          } else  if(trackingEnableTime) {
            let enableEta1 = cloneDeep(eta);
            trackingEnableETA = enableEta1.setMinutes(enableEta1.getMinutes()-trackingEnableTime);
            if(new Date().getTime()<trackingEnableETA){
              let diffTime = Math.abs(trackingEnableETA-(new Date().getTime()));
              enableEta = Math.ceil(diffTime/(1000*60));
            }
          }
        if(max && max>0){
          let eta1  = cloneDeep(eta);
          eta1.setMinutes(eta1.getMinutes()+max);
          maxEta=(timeFormat==CONSTANTS.TWENTYFOUR_HOUR_FORMAT)?this.formatAccordingTo24Hour(eta1):this.formatAMPM(eta1);
          // maxEta = eta1.getHours()>12?(eta1.getHours()-12)+":"+(eta1.getMinutes()>9?eta1.getMinutes():"0"+eta1.getMinutes())+" PM":eta1.getHours()+":"+(eta1.getMinutes()>9?eta1.getMinutes():"0"+eta1.getMinutes())+" AM"
        }
        if(minEta && maxEta){
          actualEta = actualEta;
          let time =minEta+" - "+maxEta;
          return {
             "actualEta":actualEta,
             "enableEta":enableEta,
             "time":time
            }
        } else if(minEta) {
          actualEta = actualEta;
          let time = minEta;
          return {
            "actualEta":actualEta,
            "enableEta":enableEta,
            "time":time
           }
        } else if(maxEta){
          actualEta = actualEta;
          let time = maxEta;
          return {
            "actualEta":actualEta,
            "enableEta":enableEta,
            "time":time
           };
        } else {
          let t = '';
          if(timeFormat==CONSTANTS.TWENTYFOUR_HOUR_FORMAT){
            t=this.formatAccordingTo24Hour(eta);
          }else{
            t=eta.getHours()>12?(eta.getHours()-12)+":"+(eta.getMinutes()>9?eta.getMinutes():"0"+eta.getMinutes())+" PM":eta.getHours()+":"+(eta.getMinutes()>9?eta.getMinutes():"0"+eta.getMinutes())+" AM"
          }
          actualEta = actualEta;
          return {
            "actualEta":actualEta,
            "enableEta":enableEta,
            "time":t
           }
        }
      }

    
  async getPreviousJobsInfo(url, jobId, jobTransactionId, statusListToExclude) {
    return await restClientService.fetchPreviousJobsInfo(url, jobId, jobTransactionId, statusListToExclude);
  }

}

export const enhancedLiveTrackingService = new EnhancedLiveTrackingService();
