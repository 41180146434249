
import React from 'react';
import {isEmpty} from 'loadsh'
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import {eventService} from '../../containers/EventScreen/EventService';
import { MyContext } from '../../containers/context';
import {Call, Email, Edit, Pudo,ChatIcon} from '../HelpSection/icons';
// import ChatIcon from '../../Utils/icons/ChatIcon';



class HelpSection extends React.Component {

    constructor(props) {
        super(props);
        this.fontType=`${this.props.trackingDetails?.newLayout?this.props.trackingDetails?.customerInteractionThemeSettingsDTO?.newCiData?.defaultSettings?.typeface?.fontType:"Roboto"},sans-serif`;
    }

    getProcessFormList =(fontSize,fontFamily)=>{
        if(!isEmpty(this.props.trackingDetails) && this.props.trackingDetails.processUpdateForm){
         let forms = JSON.parse(this.props.trackingDetails.processUpdateForm);
         let arrayList  = [];
         if(forms && forms.formList && !isEmpty(forms.formList)){
            (forms.formList).map((form,index)=>{
              if(form.introText)
              arrayList.push(<ListItem button id="processUpdateForm" key ={index} onClick={this.props.openProcessForm(index)}>
                    <Typography component="span">
                    <Edit fill={this.props.primaryBgColor} />
                     <span style={{fontFamily:this.fontType, fontSize:fontSize}} className='ml15'>   {form.introText}</span>
                    
                    </Typography>
                </ListItem>
                )
             })
         }
         return arrayList;
    }
}
    getDrawerNeedHelpText = () => {
        if (this.props.trackingDetails?.drawerNeedHelp && !isEmpty(this.props.trackingDetails.drawerNeedHelp)) {
            return this.props.trackingDetails.drawerNeedHelp;
        }
        return containerConstants.formatString(containerConstants.NeedHelp);
    }
    renderSupportDetail = (showSupportDetail,callSupport,emailSupport,emailSubject,helpLineLink,helpLineLinkLabel,fontSize)=>{
        let emailSupportLabel,phoneSupportLabel;
        if(this.props.trackingDetails?.customerInteractionThemeSettingsDTO?.emailLabel){
            emailSupportLabel= this.props.trackingDetails?.customerInteractionThemeSettingsDTO?.emailLabel;
        }
        if(this.props.trackingDetails?.customerInteractionThemeSettingsDTO?.phoneLabel){
            phoneSupportLabel= this.props.trackingDetails?.customerInteractionThemeSettingsDTO?.phoneLabel;
        }
        if(this.props.trackingDetails?.ciDiyPreview){
            return showSupportDetail? <>
                {<a style={{fontSize:this.props.fontSize}}><ListItem button>
                    <Typography component="span" id="callSupportButton">
                        <Call fill={this.props.primaryBgColor} />
                        <span className='ml15' id="callSupportButton" style={{fontFamily:this.fontType}}>{containerConstants.formatString(containerConstants.CallSupport)}</span>
                    
                    </Typography>
                </ListItem></a>}
                {<a style={{fontSize:this.props.fontSize}} target="_blank"><ListItem button>

                    <Typography component="span" id="emailButton">
                        <Email fill={this.props.primaryBgColor} />
                        <span className='ml15' id="emailButton" style={{fontFamily:this.fontType}}>{containerConstants.formatString(containerConstants.EmailSupport)}</span>
                    
                    </Typography>
                </ListItem></a>}
                {<a  target='_blank' style={{ fontSize:this.props.fontSize}}><ListItem button>
                    <Typography component="span" id="helpLineLink">
                        <div><ChatIcon fill={this.props.primaryBgColor?this.props.primaryBgColor:'#212121'} /></div>
                        <div className='ellipse-chat ml15' id="helpLineLink" style={{fontFamily:this.fontType}}>{!isEmpty(helpLineLinkLabel)?helpLineLinkLabel:containerConstants.formatString(containerConstants.HelpLineLink)}</div>
                    
                    </Typography>
                </ListItem></a>}
            </>:<></>
        }
        else{
            return showSupportDetail? <>
                    {callSupport?<a  href={"tel:"+callSupport} style={{fontSize:this.props.fontSize}} target="_blank"><ListItem button>
                        <Typography component="span" id="callSupportButton">
                            <Call fill={this.props.primaryBgColor} />
                            <span className='ml15' id="callSupportButton" style={{fontFamily:this.fontType,fontSize,}}>{phoneSupportLabel||containerConstants.formatString(containerConstants.CallSupport)}</span>
                        
                        </Typography>
                    </ListItem></a>:null}
                    {emailSupport?<a  href={`mailto:${emailSupport}?subject=${emailSubject}`} style={{fontSize:this.props.fontSize}} target="_blank"><ListItem button>

                        <Typography component="span" id="emailButton">
                            <Email fill={this.props.primaryBgColor} />
                            <span className='ml15' id="emailButton" style={{fontFamily:this.fontType,fontSize,}}>{emailSupportLabel||containerConstants.formatString(containerConstants.EmailSupport)}</span>
                        
                        </Typography>
                    </ListItem></a>:null}
                    {helpLineLink?<a  href={helpLineLink} target='_blank' style={{ fontSize:this.props.fontSize}}><ListItem button>
                        <Typography component="span" id="helpLineLink">
                            <div><ChatIcon fill={this.props.primaryBgColor?this.props.primaryBgColor:'#212121'} /></div>
                            <div className='ellipse-chat ml15' id="helpLineLink" style={{fontFamily:this.fontType,fontSize,}}>{!isEmpty(helpLineLinkLabel)?helpLineLinkLabel:containerConstants.formatString(containerConstants.HelpLineLink)}</div>
                        
                        </Typography>
                    </ListItem></a>:null}
                </>:<></>
        }
    }
    getSection =()=>{
         let callSupport,emailSupport, helpLineLinkLabel, helpLineLink,emailSubject="",showSupportDetail=true;
         if(this.props.trackingDetails && this.props.trackingDetails.help && !isEmpty(this.props.trackingDetails.help)){
            let help = JSON.parse(this.props.trackingDetails.help);
            if(help.helpLineContact && !isEmpty(help.helpLineContact) && help.helpLineContact.length > 2){
                callSupport = help.helpLineContact;
            }
            if(help.helpLineEmail && !isEmpty(help.helpLineEmail) && help.helpLineEmail.length > 2){
                emailSupport =  help.helpLineEmail;
             }
             if(help.helpLineLinkLabel && !isEmpty(help.helpLineLinkLabel)){
                helpLineLinkLabel =  help.helpLineLinkLabel;
             }
             if(help.helpLineLink && !isEmpty(help.helpLineLink)){
                helpLineLink =  help.helpLineLink;
             }
             if(help.helpLineEmailSubject && !isEmpty(help.helpLineEmailSubject)){
                emailSubject =  help.helpLineEmailSubject;
             }
             if(help.showSupportDetail != undefined){
                showSupportDetail=help.showSupportDetail;   
             }
          }
          // using for UniversalSearch
          if(this.props.calledFromUniversal){
          callSupport = this.props.callSupport;
          emailSupport = this.props.emailSupport;
          this.fontType=`${this.props.newThemeData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`;
          }
        
      return(
        <MyContext.Consumer>
        {({ 
         primaryBgColor, 
         primaryTextColor,
         secondaryBgColor,
         secondaryTextColor,
         fontSize,
         fontFamily, 
      }) => (
          <div className="help_screen">
              <p className="fs16 p15 title" style={{fontFamily:this.fontType, fontSize:fontSize}}>
              {this.getDrawerNeedHelpText()}   
                <img src={require('../../images/cancel.svg')} height='15px' className="fl-right" onClick={this.props.setHelpSection} />
              </p>
              
              <List>
                  {!this.props.isSurveyFormNeedHelp && this.getProcessFormList(fontSize,fontFamily)}

                {!this.props.isSurveyFormNeedHelp && (this.props.trackingDetails && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true) &&
                <ListItem button>
                    <Typography component="span" onClick={(e)=>this.props.openPudoScreen(e)} id="pudoFromNeedHelp">
                        {/* <StorefrontIcon fontSize='small' className="mr10 ml0 pl0"/> */}

                        <Pudo id="pudoFromNeedHelp" fill={this.props.primaryBgColor} />
                        <div id="pudoFromNeedHelp" style={{fontFamily:this.fontType, fontSize:fontSize}} className='ml15'>
                            <div className="collectPoints" id="pudoFromNeedHelp">
                                {this.props.pudoTitle ?
                                    this.props.pudoTitle?.length > 32 ? 
                                        <>{this.props.pudoTitle?.substring(0, 32)}&hellip;</>
                                        :
                                        this.props.pudoTitle
                                    :
                                    "Collect from pickup point"
                                }
                                {(this.props.pudoPoints && this.props.pudoPoints.length > 0) ?
                                    <>
                                        {this.props.subLabel ?
                                            this.props.subLabel.indexOf("{STORE}")!=-1?
                                                <p className="fs12">{this.props.subLabel.substring(0,this.props.subLabel.indexOf("{STORE}"))+' '+this.props.pudoPoints.length+''+this.props.subLabel.substring(this.props.subLabel.indexOf("{STORE}")+7)}</p>
                                                :
                                                <p className="fs12">{this.props.subLabel}{' '}{this.props.pudoPoints.length}</p>
                                            : 
                                            <p className="fs12">Select from over {this.props.pudoPoints.length} store(s) around you</p>
                                        }
                                    </> 
                                    : 
                                    ''
                                }
                            </div>
                        </div>
                    </Typography>
                </ListItem>}
                {
                    this.renderSupportDetail(showSupportDetail,callSupport,emailSupport,emailSubject,helpLineLink,helpLineLinkLabel,fontSize)
                }
              </List>
          </div>
        )}
    </MyContext.Consumer>
      )
    }

   getHelpSection =()=>{
       return (
            <Drawer 
             anchor="bottom"
             open={this.props.showNeedHelpDialog}
             onClose={ this.props.setHelpSection}>
             {this.getSection()}
           </Drawer>
       )
   }

   

    render(){
        return(
                <div>
                {this.getHelpSection()}
                {/* {this.props.openPudoScreen && <AvailableLocation/>} */}
            </div>
        )
    }
}


export default HelpSection;