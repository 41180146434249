import React from "react";
import StoreClickCountLabelConstants from "../../Utils/StoreClickCountlabelConstants";

export const Call = (props) => {
  return (
    
    <svg xmlns="http://www.w3.org/2000/svg" fill={props.color} width="18px" height="18px" style={props.style}>
      <path d="M3.54 2C3.6 2.89 3.75 3.76 3.99 4.59L2.79 5.79C2.38 4.59 2.12 3.32 2.03 2H3.54ZM13.4 14.02C14.25 14.26 15.12 14.41 16 14.47V15.96C14.68 15.87 13.41 15.61 12.2 15.21L13.4 14.02ZM4.5 0H1C0.45 0 0 0.45 0 1C0 10.39 7.61 18 17 18C17.55 18 18 17.55 18 17V13.51C18 12.96 17.55 12.51 17 12.51C15.76 12.51 14.55 12.31 13.43 11.94C13.33 11.9 13.22 11.89 13.12 11.89C12.86 11.89 12.61 11.99 12.41 12.18L10.21 14.38C7.38 12.93 5.06 10.62 3.62 7.79L5.82 5.59C6.1 5.31 6.18 4.92 6.07 4.57C5.7 3.45 5.5 2.25 5.5 1C5.5 0.45 5.05 0 4.5 0Z" fill="#212121"/>
    </svg>
  );
};

export const Email = (props) => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill={props.color} style={props.style} xmlns="http://www.w3.org/2000/svg">
      <path d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17 14H3C2.45 14 2 13.55 2 13V4L8.94 8.34C9.59 8.75 10.41 8.75 11.06 8.34L18 4V13C18 13.55 17.55 14 17 14ZM10 7L2 2H18L10 7Z" fill="#212121"/>
    </svg>
  );
};
export const ChatIcon = (props) => {
  return (
  <svg width="25" height="20" viewBox="0 0 20 16" fill={props.color} style={props.style} xmlns="http://www.w3.org/2000/svg">
      <path 
          d="M3 3H15V12H3.8775L3 12.8775V3ZM3 1.5C2.175 1.5 1.5075 2.175 1.5075 3L1.5 16.5L4.5 13.5H15C15.825 13.5 16.5 12.825 16.5 12V3C16.5 2.175 15.825 1.5 15 1.5H3ZM4.5 9H13.5V10.5H4.5V9ZM4.5 6.75H13.5V8.25H4.5V6.75ZM4.5 4.5H13.5V6H4.5V4.5Z" 
          // fill={props.fill?props.fill:"#56c09a"}
          fill={"#212121"}
      />
  </svg>)
};
export const Edit = (props) => {
  return (
    <svg width="19" height="18" viewBox="0 0 20 16" fill={props.color} style={props.style} xmlns="http://www.w3.org/2000/svg">
      <path d="M11.06 6.02L11.98 6.94L2.92 16H2.25C2.11193 16 2 15.8881 2 15.75V15.08L11.06 6.02ZM14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0ZM11.06 3.19L0 14.25V17.5C0 17.7761 0.223858 18 0.5 18H3.75L14.81 6.94L11.06 3.19Z" fill="#212121"/>
    </svg>
  );
};

export const Pudo = (props) => {
  return (
    <svg width="18" height="16" viewBox="0 0 20 16" fill={props.color} style={props.style} xmlns="http://www.w3.org/2000/svg">
      <path d="M15.36 5L15.96 8H2.04L2.64 5H15.36ZM17 1C17 0.447715 16.5523 0 16 0H2C1.44772 0 1 0.447715 1 1C1 1.55228 1.44772 2 2 2H16C16.5523 2 17 1.55228 17 1ZM17.1608 3.80388C17.0673 3.33646 16.6569 3 16.1802 3H1.8198C1.34312 3 0.932708 3.33646 0.839223 3.80388L0 8V9C0 9.55229 0.447715 10 1 10V15C1 15.5523 1.44772 16 2 16H10C10.5523 16 11 15.5523 11 15V10H15V15C15 15.5523 15.4477 16 16 16C16.5523 16 17 15.5523 17 15V10C17.5523 10 18 9.55229 18 9V8L17.1608 3.80388ZM3 14V10H9V14H3Z" fill="#212121"/>
    </svg>
  );
};
export const ShipmentIcon = (props) => {
  return (
    
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.98 4.13414L8.48 1.12477C8.33305 1.04357 8.16789 1.00098 8 1.00098C7.83211 1.00098 7.66695 1.04357 7.52 1.12477L2.02 4.13539C1.86293 4.22133 1.73181 4.34787 1.64034 4.50178C1.54888 4.6557 1.50041 4.83135 1.5 5.01039V10.9879C1.50041 11.1669 1.54888 11.3426 1.64034 11.4965C1.73181 11.6504 1.86293 11.777 2.02 11.8629L7.52 14.8735C7.66695 14.9547 7.83211 14.9973 8 14.9973C8.16789 14.9973 8.33305 14.9547 8.48 14.8735L13.98 11.8629C14.1371 11.777 14.2682 11.6504 14.3597 11.4965C14.4511 11.3426 14.4996 11.1669 14.5 10.9879V5.01102C14.4999 4.83166 14.4516 4.65561 14.3601 4.50134C14.2686 4.34706 14.1373 4.22024 13.98 4.13414ZM8 1.99977L13.0212 4.74977L11.1606 5.76852L6.13875 3.01852L8 1.99977ZM8 7.49977L2.97875 4.74977L5.0975 3.58977L10.1187 6.33977L8 7.49977ZM2.5 5.62477L7.5 8.36102V13.7229L2.5 10.9885V5.62477ZM13.5 10.986L8.5 13.7229V8.36352L10.5 7.26914V9.49977C10.5 9.63238 10.5527 9.75955 10.6464 9.85332C10.7402 9.94709 10.8674 9.99977 11 9.99977C11.1326 9.99977 11.2598 9.94709 11.3536 9.85332C11.4473 9.75955 11.5 9.63238 11.5 9.49977V6.72164L13.5 5.62477V10.9854V10.986Z" fill="#647788"/>
</svg>
  );
};
export const ShipmentIconV2 = (props) => {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.97 5.20156L10.72 0.687502C10.4996 0.565704 10.2518 0.501816 10 0.501816C9.74816 0.501816 9.50043 0.565704 9.28 0.687502L1.03 5.20344C0.794395 5.33235 0.597722 5.52215 0.460517 5.75303C0.323313 5.9839 0.25061 6.24737 0.25 6.51594V15.4822C0.25061 15.7508 0.323313 16.0142 0.460517 16.2451C0.597722 16.476 0.794395 16.6658 1.03 16.7947L9.28 21.3106C9.50043 21.4324 9.74816 21.4963 10 21.4963C10.2518 21.4963 10.4996 21.4324 10.72 21.3106L18.97 16.7947C19.2056 16.6658 19.4023 16.476 19.5395 16.2451C19.6767 16.0142 19.7494 15.7508 19.75 15.4822V6.51688C19.7499 6.24783 19.6774 5.98377 19.5402 5.75236C19.403 5.52095 19.206 5.33071 18.97 5.20156ZM10 2L17.5319 6.125L14.7409 7.65313L7.20813 3.52813L10 2ZM10 10.25L2.46812 6.125L5.64625 4.385L13.1781 8.51L10 10.25ZM1.75 7.4375L9.25 11.5419V19.5847L1.75 15.4831V7.4375ZM18.25 15.4794L10.75 19.5847V11.5456L13.75 9.90407V13.25C13.75 13.4489 13.829 13.6397 13.9697 13.7803C14.1103 13.921 14.3011 14 14.5 14C14.6989 14 14.8897 13.921 15.0303 13.7803C15.171 13.6397 15.25 13.4489 15.25 13.25V9.08282L18.25 7.4375V15.4784V15.4794Z" fill="#647788"/>
</svg>

  );
};

export const OpenShipmentIcon = (props) => {
  return (
    // <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    // <rect width="32" height="32" rx="16" fill="#F3F3F3"/>
    // <path d="M21.0306 16.5306L13.5306 24.0306C13.4609 24.1002 13.3781 24.1555 13.2871 24.1932C13.1961 24.2309 13.0985 24.2503 12.9999 24.2503C12.9014 24.2503 12.8038 24.2309 12.7128 24.1932C12.6217 24.1555 12.539 24.1002 12.4693 24.0306C12.3996 23.9609 12.3443 23.8781 12.3066 23.7871C12.2689 23.6961 12.2495 23.5985 12.2495 23.4999C12.2495 23.4014 12.2689 23.3038 12.3066 23.2128C12.3443 23.1217 12.3996 23.039 12.4693 22.9693L19.4396 15.9999L12.4693 9.03055C12.3286 8.88982 12.2495 8.69895 12.2495 8.49993C12.2495 8.30091 12.3286 8.11003 12.4693 7.9693C12.61 7.82857 12.8009 7.74951 12.9999 7.74951C13.199 7.74951 13.3898 7.82857 13.5306 7.9693L21.0306 15.4693C21.1003 15.539 21.1556 15.6217 21.1933 15.7127C21.2311 15.8038 21.2505 15.9014 21.2505 15.9999C21.2505 16.0985 21.2311 16.1961 21.1933 16.2871C21.1556 16.3782 21.1003 16.4609 21.0306 16.5306Z" fill={props.color}/>
    // </svg>

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" id={StoreClickCountLabelConstants.OPEN_SHIPMENT}>
<rect width="24" height="24" rx="12" fill="#F3F3F3"/>
<path d="M15.3535 12.3538L10.3535 17.3538C10.3071 17.4002 10.2519 17.4371 10.1912 17.4622C10.1305 17.4873 10.0655 17.5003 9.99979 17.5003C9.93409 17.5003 9.86904 17.4873 9.80834 17.4622C9.74764 17.4371 9.69249 17.4002 9.64604 17.3538C9.59958 17.3073 9.56273 17.2521 9.53759 17.1915C9.51245 17.1308 9.49951 17.0657 9.49951 17C9.49951 16.9343 9.51245 16.8693 9.53759 16.8086C9.56273 16.7479 9.59958 16.6927 9.64604 16.6463L14.2929 12L9.64604 7.35375C9.55222 7.25993 9.49951 7.13269 9.49951 7C9.49951 6.86732 9.55222 6.74007 9.64604 6.64625C9.73986 6.55243 9.86711 6.49973 9.99979 6.49973C10.1325 6.49973 10.2597 6.55243 10.3535 6.64625L15.3535 11.6463C15.4 11.6927 15.4369 11.7478 15.4621 11.8085C15.4872 11.8692 15.5002 11.9343 15.5002 12C15.5002 12.0657 15.4872 12.1308 15.4621 12.1915C15.4369 12.2522 15.4 12.3073 15.3535 12.3538Z" fill={props.color}/>
</svg>


  );
};
export const ReturnToOrderIcon = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7806 8.53067L11.0306 15.2807C10.8899 15.4214 10.699 15.5005 10.5 15.5005C10.301 15.5005 10.1101 15.4214 9.96937 15.2807C9.82864 15.1399 9.74958 14.9491 9.74958 14.75C9.74958 14.551 9.82864 14.3602 9.96937 14.2194L15.4397 8.75005H0.75C0.551088 8.75005 0.360322 8.67103 0.21967 8.53038C0.0790178 8.38973 0 8.19896 0 8.00005C0 7.80114 0.0790178 7.61037 0.21967 7.46972C0.360322 7.32907 0.551088 7.25005 0.75 7.25005H15.4397L9.96937 1.78068C9.82864 1.63995 9.74958 1.44907 9.74958 1.25005C9.74958 1.05103 9.82864 0.860156 9.96937 0.719426C10.1101 0.578695 10.301 0.499634 10.5 0.499634C10.699 0.499634 10.8899 0.578695 11.0306 0.719426L17.7806 7.46942C17.8504 7.53908 17.9057 7.6218 17.9434 7.71284C17.9812 7.80389 18.0006 7.90149 18.0006 8.00005C18.0006 8.09861 17.9812 8.19621 17.9434 8.28725C17.9057 8.3783 17.8504 8.46102 17.7806 8.53067Z" fill="#05223D"/>
</svg>

  );
};



