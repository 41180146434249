const dutchString = {
    YourShipmentHasBeenDelivered: 'Uw zending is bezorgd',
    DeliveredBy: 'Bezorgd door',
    RateYourExperience:	'Beoordeel uw ervaring',
    ThankYouForYourValuableFeedback: 'Dank u voor uw waardevolle feedback',
    YourFeedbackWillContinuallyHelpUsImproveOurServices: 'Uw feedback helpt ons voortdurend onze diensten te verbeteren',
    DeliveryFailed: 'Levering mislukt',
    AddDeliveryPreferences: 'Leveringsvoorkeuren toevoegen',
    YourDeliveryPreferenceAreSavedSuccessFully: 'Uw leveringsvoorkeuren zijn opgeslagen',
    WeAreUnableToTrackYourShipmentRightNow: 'Wij kunnen uw zending nu niet traceren',
    PleaseUpdateLocationInsideCircle: 'Herzie de locatie binnen de cirkel',
    Ok: 'OK',
    Update: 'Herzien',
    PickCurrentLocation: 'Kies huidige locatie',
    SearchFor: 'Zoek naar',
    ThisSchedulingLinkHasExpired: 'De planningslink is verlopen',
    WeWillShareANewLinkWithYouShortly: 'Wij zullen binnenkort een nieuwe link met u delen',
    UhHo: 'O, nee!',
    NeedHelp: 'HULP NODIG?',
    CallSupport: 'Bel de ondersteuning',
    EmailSupport: 'E-mail de ondersteuning',
    DeliveryAt:'Bezorging op',
    ContactNo: 'Contactnummer',
    TrackOnMap: 'Op kaart traceren',
    MessageToExecutive: 'Bericht aan directeur',
    Cancel: 'Annuleren',
    Send: 'Zenden',
    Executive: 'Directeur',
    Comments: 'Opmerkingen (optioneel)',
    SubmitFeedback: 'FEEDBACK INDIENEN',
    TrackingNo: 'Traceernummer',
    Details: 'Details',
    Characters: 'Tekens',
    Updating: 'bezig met updaten…',
    OopsSomethingWentWrongPleaseTryAfterSomeTime: 'O! Er is iets fout gegaan, probeer het na enige tijd nog eens',
    NotListedAboveSendACustomMessage: 'Niet hierboven genoemd, stuur een aangepast bericht',
    ChangeDeliveryLocation: 'Bestellocatie wijzigen',
    AttemptedBy: 'Getracht door',
    DeliveredTo: 'Bezorgd aan',
    SendMessage: 'BERICHT ZENDEN',
    VIEW_DETAILS: 'Details bekijken',
    SAVE_LOCATION: 'LOCATIE OPSLAAN',
    LOCATION: 'locatie',
    SEARCH_ADDRESS: 'Adres zoeken',
    OpenHours: 'Open uren',
    Note: 'Opmerking',
    Address: 'Adres',
    Nearest: 'Dichtstbijzijnd',
    ContactNumber: 'Contactnummer',
    FAQS: 'Veelgestelde vragen',
    HowManyTimesCanIChangeMyPickUpPoint: 'Hoe vaak kan ik mijn afhaalpunt wijzigen?',
    YouCanChangeItOnly: 'U kunt het maar … wijzigen',
    GoBack: 'Teruggaan',
    SetAsPickupStore: 'Instellen als afhaalwinkel',
    MapView: 'Kaartweergave',
    SearchForPickupPointNearYou: 'Zoek een afhaalpunt bij u in de buurt',
    Arriving: 'Komt aan',
    LiveTrackingWillStart: 'Live traceren begint',
    DriverTrackerWillGetActivatedOnceTheyAreOnWay: 'De tracker van de chauffeur wordt geactiveerd zodra hij/zij op weg is',
    TrackerTimeline: 'Tracker-tijdlijn',
    PM: 'Middag',
    AM: 'Voor de middag',
    TodayAt: 'vandaag om',
    TomorrowAt: 'morgen om',
    Today: 'Vandaag',
    Tomorrow: 'Morgen',
    On: 'op',
    In: 'in',
    Soon: 'binnenkort',
    Min: 'min.',
    Mins: 'min.',
    Items: 'ITEMS',
    ViewDetails: 'Details bekijken',
    DeliveryInformation: 'Bestelinformatie',
    TrackingHistory: 'Trackingeschiedenis',
    StayConnected: 'Blijf in contact',
    YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'U hebt enkele minuten geleden al geprobeerd een betaling te doen. Wilt u uw vorige transacties annuleren',
    No: 'NEE',
    yes: 'JA',
    YourPackageWillBeDeliveredBy: 'Uw pakket wordt besteld op',
    OrderDetails: 'Bestelgegevens',
    OrderInformation: 'Bestelinformatie',
    ThankYou: 'Dank u',
    LastAttempted: 'Laatste poging',
    DeliveryBy: 'Levering op',
    MsgToFePageHeader: 'Bestelinstructies zenden',
    MsgToFeSupportingText: 'Wat wilt u de chauffeur zeggen?',
    AddYourMsgHere: 'Voeg uw bericht hier toe',
    InstructionsSentSuccessfully: 'Instructies zijn verzonden',
    SomethingWentWrongPlsTryAgain: 'Er is iets fout gegaan, probeer het opnieuw',
    times: 'maal',
    TrackMovementInRealTime: 'Volg traect in realtime',
    DeliveredAt: 'Bezorgd op',
    LastAttempted: 'Laatste poging',
    Tomorrow: 'Morgen',
    Submit: 'Indienen',
	WriteYourFeedbackOptional:'Schrijf uw feedback (optioneel)',
	OrderInformation:'Bestelinformatie',
	TrackingTimeline:'Tijdslijn traceren',
    Pickuppointisupdated: 'Ophaalpunt is bijgewerkt',
    Hour: 'Uur',
    Hours:'Uren',
    WriteYourFeedbackOptional:"Schrijf uw feedback (optioneel)",
    ReturnOrder: "Return Order",
    TheLinkYouAreTryingToOpenHasExpired: "De link die u probeert te openen is verlopen    ",
    SelectAnAvailableTimeSlot:"Select an available time slot",
    January:"JANUARY",
    March :"MARCH",
    April:"APRIL",
    May:"MAY",
    June:"JUNE",
    July:"JULY",
    August:"AUGUST",
    September:"SEPTEMBER",
    October:"OCTOBER",
    November:"NOVEMBER",
    December:"DECEMBER",
    Monday:"MONDAY",
    Tuesday:"TUESDAY",
    Wednesday:"WEDNESDAY",
    Thursday:"THURSDAY",
    Friday:"FRIDAY",
    Saturday:"SATURDAY",
    Sunday:"SUNDAY",
    February:"FEBRUARY",
    ViewTimings: "View Timings",
    HideTimings: "Hide Timings",
    PleaseAnswerAllMandatoryQuestions:"Gelieve alle verplichte vragen te beantwoorden",
    HelpLineLink: 'Helpline Link',
    ShipmentOf: "Zending%van",
    OtherShipments: "Andere Zendingen",
    OrderInformation:"Bestelinformatie",
    OR: "of",
    TrackShipment:"Zending volgen",
    TrackingDescription:"Voer de onderstaande gegevens in om de details van uw zending te krijgen",
    Continue:"Doorgaan",
    InvalidCredentials:"Ongeldige inloggegevens. Probeer het opnieuw.",
    ITEM: 'item',
    ITEMS: 'item(s)',
    OrderNo:"Bestelnummer",
    CurrentStatus:"Huidige status",
PickupTime:"Ophaaltijd",
CopiedtoClipboard:"Copied to Clipboard",
No_order_found:"No order found!",
Qty:"hvl",
}

export default dutchString;