import React, { Component } from 'react';
import { LocationStoreIcon, StoreClockIcon, StoreCallIcon, StoreArrowIcon } from "../PudoStore/Icons";
import ArrivingEta from '../ArrivingEta';
import { isEmpty } from 'lodash';
import { PudoDirectionsIcon } from '../../containers/LayoutScreen/PudoReturnMap/Icons';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { PudoOtherDetails } from '../PudoOtherDetails';
import PudoIcon from '../../Utils/icons/PudoIcon';
import { getBorderValue } from '../../Utils/themeConfig';

class PudoStoreDetails extends Component {
  
  state={
    timingDropDown:false,
    timingText: containerConstants.formatString(containerConstants.ViewTimings),
  }
  days=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  getAddress=(storeInfo)=>{
    let address ='';
    if(storeInfo.pudoAddressLine1)
    address+=storeInfo.pudoAddressLine1;
    if(storeInfo.pudoAddressLine2)
    address=!isEmpty(address)?address+","+storeInfo.pudoAddressLine2:storeInfo.pudoAddressLine2;
    if(storeInfo.pudoLandMark)
    address=!isEmpty(address)?address+','+storeInfo.pudoLandMark:storeInfo.pudoLandMark;
    if(storeInfo.pudoPincode)
    address=!isEmpty(address)?address+","+storeInfo.pudoPincode:storeInfo.pudoPincode;

    return address;
   }
   showTiming =()=>{
    var timingText;
    if(this.state.timingDropDown)
    timingText = containerConstants.formatString(containerConstants.ViewTimings);
    else 
    timingText = containerConstants.formatString(containerConstants.HideTimings);

    this.setState({timingDropDown:!this.state.timingDropDown,timingText:timingText})
   }
   dropDownMenu=(timings)=>{
    let i=0;
    let list=[];
    for(i=0;i<7;i++){
      if(!isEmpty(timings[i])){list.push(<div className="pb5 pt5" style={{display:'flex'}}><p style={{width:'80px' }}>{this.days[i]}</p> {timings[i]}</div>)}
    }
    return list;
  }
  render() {
    let storeInfo =  this.props.trackingDetails && this.props.trackingDetails.storeInfo ?this.props.trackingDetails.storeInfo :'';
    let location =  storeInfo && storeInfo.pudoLocation? storeInfo.pudoLocation:'';
    let locationArr = location.split(",");
    let lat='', log='';
    if(locationArr.length==2){
      lat=locationArr[0];
      log=locationArr[1];
    }
    let timings = "";
    if(!isEmpty(storeInfo.pudoTiming)) {
      timings = storeInfo.pudoTiming.split(',');
    }
    var trimmedStoreName = "";
    if(!isEmpty(storeInfo) && !isEmpty(storeInfo.pudoShop) && storeInfo.pudoShop.length>28){
      trimmedStoreName = storeInfo.pudoShop.substr(0, 24) + "...";
    }
    let border,styleObj={};
    if(this.props.trackingDetails?.newLayout){
      border = getBorderValue(this.props.cardTheme?.borderColour||'');
      styleObj={
        width:"100%",
        backgroundColor:this.props.cardTheme?.cardBgcolour,
        borderRadius:`${this.props.cardTheme?.cornerRedius||0}px`,
        borderTop:border[0],
        borderRight:border[1],
        borderBottom:border[2],
        borderLeft:border[3],
      }
    }
    return(
      <>
        {!isEmpty(storeInfo)  &&
        <div className='ci-pudo-order-details-desktop-container' style={styleObj}>
        <div className='ci-pudo-order-details-banner'>
            <div className='ci-pudo-order-details-banner-img'>
                {/* <img src={require('../../images/banner/pudo-order-banner.png')} alt=""/> */}
                 <PudoIcon 
                 color={this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && this.props.trackingDetails.customerInteractionThemeSettingsDTO.primaryBgColor}
                 />
            </div>
            <div className='ci-pudo-order-details-banner-text'>
               <h2><ArrivingEta
                  eta={this.props.updateEta}
                  trackingDetails={this.props.trackingDetails}
                  secondaryTextColor={this.props.secondaryTextColor}
                  url={this.props.url}
                  isJobRemoved={this.props.isJobRemoved}
                  isReturn={this.props.isReturn}
                  tripEta={this.props.tripEta}
                /></h2>
                {storeInfo.pudoShop && 
                <span>
                  Your order will be delivered to our pickup store 
                  <div title={trimmedStoreName?storeInfo.pudoShop:""}>"{trimmedStoreName?trimmedStoreName:storeInfo.pudoShop}"</div>
                </span>}
            </div>
        </div>
       {!isEmpty(storeInfo) && (storeInfo.pudoAddressLine1 || storeInfo.pudoAddressLine2 || storeInfo.pudoLandMark || storeInfo.pudoPincode || storeInfo.pudoLocation || storeInfo.pudoTiming || (storeInfo.pudoContactNumber && this.props.showContact) || (storeInfo.pudoLocation && this.props.showDirection)) && 
         <div className='ci-pudo-order-store-details-box'>
         <h4>Store Details</h4>
         {!isEmpty(this.getAddress(storeInfo)) &&
           <div className='ci-pudo-order-store-details-row'>
             <LocationStoreIcon />
             <p>{this.getAddress(storeInfo)}
               {storeInfo.pudoLocation && this.props.showDirection ? <a style={{display: "flex"}} href={'https://maps.google.com/?q=' + lat + ',' + log} target="_blank" id="getDirectionDesktop"><PudoDirectionsIcon/>Get Directions </a>:null}
             </p>
           </div>
         }
         {storeInfo.pudoTiming &&
           <div className='ci-pudo-order-store-details-row'>
             <StoreClockIcon />
             <p  onClick ={()=>{this.showTiming()}}><strong style={{color:!this.state.timingDropDown?"#3F98D8":'#05223D',cursor:'pointer',lineHeight: "23px"}}>{this.state.timingText}</strong>
             {this.state.timingDropDown? <UpOutlined  style={{color:"#05223D"}}/>:
               <DownOutlined className="" style={{color:"#3F98D8"}}/>
             }
             {this.state.timingDropDown && this.dropDownMenu(timings)}
             </p> 
           </div>
         }
         {storeInfo.pudoContactNumber && this.props.showContact &&
           <div className='ci-pudo-order-store-details-row'>
             <StoreCallIcon />
             <a href={"tel:"+storeInfo.pudoContactNumber} className='no-anchor' id="callStoreDesktop"><bdi>{storeInfo.pudoContactNumber}</bdi></a>
           </div>
         }
        {storeInfo.pudoOtherDetails &&
                  <div className='ci-pudo-order-store-details-row'>
                    <p> <PudoOtherDetails otherDetails={storeInfo.pudoOtherDetails} /></p>
                  </div>
        }
       </div>
       }
    </div>}
      </>
    );
  }
}

export default PudoStoreDetails;