import axios from 'axios';
import API from '../../../Config/API';
import { isEmpty } from 'lodash';



class UniversalSearchService{
    async fetchUniversalSearchConfig(encodedString,merchantCode, languageCode){
        try{
              let hitUrl= window.location.origin + API.GET_UNIVERSAL_TRACKING_CONFIG_CI;
              const response =  await axios.get(hitUrl,{params:{encodedString:encodedString,merchantCode:merchantCode,languageCode:languageCode}});
              if(response && response.status=="200" && response.data){
                if (response.data.newThemeData) {
                  response.data.newThemeData = JSON.parse(response.data.newThemeData);
                }
                return response.data;
              }
              return;
          } catch(err){
            console.log("Error");
             return;
          }
    }
    async fetchUniversalTrackingLinks(paramsList, encodedString, combinationIndex, settingType, merchantCode){
     try {
      let hitUrl= window.location.origin + API.GET_UNIVERSAL_TRACKING_URL;
      let langCode = sessionStorage.getItem("lang");
      const response =  await axios.get(hitUrl,{params:{encodedString:encodedString,param1:paramsList.param1,param2:paramsList.param2,param3:paramsList.param3,param4:paramsList.param4,param5:paramsList.param5, combinationIndex:combinationIndex, settingType:settingType, language:langCode, merchantCode:merchantCode}});
      if(response && response.status=="200" && response.data){
       return response.data;
      }
     }
     catch(err){
       console.log("Error getting tracking links");
     }
    }

  modifyTheResponse(data) {
    if (isEmpty(data)) {
      return "";
    }
    const cleanedInput = data.slice(1, -1);
    const values = cleanedInput.split(",");
    return values[0];
  }
    
}
export const universalSearchService = new UniversalSearchService();