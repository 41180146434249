import { isEmpty } from "lodash";
import { setState } from "../../Utils/setState";
import UNIVERSAL_SEARCH from "../../Utils/universalSearchConstants";
import { universalSearchService } from "./services/UniversalSearchService";
import { commonUtilities } from "../../services/commonUtilities";


export function getUniversalSearchConfig(encodedString,merchantCode,languageCode) {
    return async dispatch => {
      const response = await universalSearchService.fetchUniversalSearchConfig(encodedString,merchantCode,languageCode);
      if(!isEmpty(response)) {
        dispatch(setState(UNIVERSAL_SEARCH.SET_UNIVERSAL_SEARCH_DATA, response));
      }
      else{
        dispatch(setState(UNIVERSAL_SEARCH.SET_LOADING));
      }
    }
  }

export function fetchUniversalTrackingLinks(paramsList, encodedString, combinationIndex, settingType, merchantCode) {
  return async dispatch => {
    const response = await universalSearchService.fetchUniversalTrackingLinks(paramsList, encodedString, combinationIndex, settingType,merchantCode);
    if (!isEmpty(response)) {
      if (typeof response[0] == "object") {
        dispatch(setState(UNIVERSAL_SEARCH.SET_SHIPMENTLIST, response));
      }
      else {
        const newModifiedResponse = universalSearchService.modifyTheResponse(response)
        if (commonUtilities.isValidURL(newModifiedResponse)) {
          dispatch(setState(UNIVERSAL_SEARCH.CI_LINK, newModifiedResponse));
        }
        else {
          dispatch(setState(UNIVERSAL_SEARCH.CI_LINK, "ERROR"));
        }
      }
    }
    else {
      dispatch(setState(UNIVERSAL_SEARCH.CI_LINK, "ERROR"));
    }
  }
}
