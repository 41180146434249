import React from 'react';
import {isEmpty} from 'loadsh'
import PudoStoreDetails from '../../components/PudoStore/PudoStoreDetails';
import { GetDirectionIcon,UpdateContactIcon } from '../../components/PudoStore/Icons';
import { getBorderValue } from '../../Utils/themeConfig';




class PudoMobileViewDetails extends React.Component {
    showPudoStoreDetail=()=>{
        let storeInfo = this.props.trackingDetails.storeInfo;
        let location =  !isEmpty(storeInfo) && storeInfo.pudoLocation? storeInfo.pudoLocation:'';
        let locationArr = location.split(",");
        let lat='', log='';
        if(locationArr.length==2){
            lat=locationArr[0];
            log=locationArr[1];
        }
            let border,styleObj={};
        if(this.props.trackingDetails?.newLayout){
        border = getBorderValue(this.props.cardTheme?.borderColour||'');
        styleObj={
            width:"100%",
            backgroundColor:this.props.cardTheme?.cardBgcolour||"white",
            borderRadius:`${this.props.cardTheme?.cornerRedius||0}px`,
            borderTop:border[0],
            borderRight:border[1],
            borderBottom:border[2],
            borderLeft:border[3],
            padding:"16px 24px",
        }
        }
        return (
            <div>
                <div className="ci-pudo-order-details-mobile" style={styleObj}>
                 <PudoStoreDetails
                    trackingDetails={this.props.trackingDetails}
                    secondaryTextColor={this.props.secondaryTextColor}
                    eta = {this.props.updateEta}
                    url = {this.props.url}
                    isJobRemoved = {this.props.isJobRemoved}
                    isReturn = {this.props.isReturn}
                    tripEta={this.props.tripEta}
                    showDirection = {false}
                    showContact = {false}
                    />
                    {(storeInfo.pudoLocation || storeInfo.pudoContactNumber) &&
                    <div className="ci-pudo-detail-get-direction-mobile">
                    {storeInfo.pudoLocation &&
                    <div className="get-direction-left" id="getDirectionMobileView">
                    <a href={'https://maps.google.com/?q='+lat+','+log} id="getDirectionMobileView" target="_blank">
                    <GetDirectionIcon id="getDirectionMobileView"/>
                    </a>
                    <a href={'https://maps.google.com/?q='+lat+','+log} id="getDirectionMobileView" target="_blank">
                    <span id="getDirectionMobileView">Get Directions</span>
                    </a>
                    
                    
                    
                </div>
                    }
                    {storeInfo.pudoContactNumber &&
                    <div className="get-direction-left pudo-mobile-call" id="callStoreMobileView">
                    <a id="callStoreMobileView" href={"tel:"+storeInfo.pudoContactNumber}>
                        <UpdateContactIcon id="callStoreMobileView"/>
                        
                    </a>
                    <a id="callStoreMobileView" href={"tel:"+storeInfo.pudoContactNumber}>
                        
                        <span id="callStoreMobileView">Call Store</span>
                    </a>
                    </div>
                    }
                </div>
            }
          </div> 
        </div>
        )
    }
  
    render(){
        if(this.props.trackingDetails && this.props.trackingDetails.screen!="feedbackScreen" 
            && this.props.trackingDetails.storeInfo &&  !isEmpty(this.props.trackingDetails.storeInfo)){
            return(
                <>
                {this.showPudoStoreDetail()}
                </>
            )
        }
    }
}

export default PudoMobileViewDetails;