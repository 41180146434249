import { isArray, isEmpty } from "lodash";
import React from "react";

export default class CurbsideOrderInfoDetail extends React.Component {
    renderOrderAttributes = () => {
        if (this.props.curbsidePickupData && this.props.curbsidePickupData.orderDetailsData && this.props.curbsidePickupData.orderDetailsData.orderDetailAttributeList && !isEmpty(this.props.curbsidePickupData.orderDetailsData.orderDetailAttributeList)) {
            return this.props.curbsidePickupData.orderDetailsData.orderDetailAttributeList.sort((a, b) => a.sequence - b.sequence).map((order, index) => {
                return (
                    <div className="curb-side-order-info-row" key={index}>
                        <label>{this.renderOrderLabel(order)}</label>
                        <p>{this.renderOrderValue(order)}</p>
                    </div>
                );
            });
        }
    }

    renderOrderLabel = (order) => {
        let label = order.label;
        if (order.arrayAttribute) {
            label = label + ` (${order.childAttributeArray.length})`
        }
        return label;
    }
    renderOrderValue = (order) => {
        if (order.arrayAttribute) {
            return this.renderArrayAttribute(order.childAttributeArray);
        } else {
            return order.value;
        }
    }

    renderArrayAttribute = (orderArrayAttr) => {
        return orderArrayAttr.map((elem, index) => {
            if (!isEmpty(elem)) {
                return (
                    <div className="c-s-item-details">
                        <div>{index + 1}.</div>
                        {
                            elem.image ?
                                <div className="c-s-item-img">
                                    {
                                        <img src={elem.image} style={{objectFit:"cover"}} />
                                    }
                                </div> :
                                <div className="c-s-item-img" style={{ padding: "5px", border: "1px solid #c8c7c7",background:"#80808030" }}>
                                    {
                                        <img src={require("../../../images/dummyFood.png")} />
                                    }
                                </div>
                        }
                        <div className="c-s-item-des">
                            {
                                elem.name && <div>{elem.name}</div>
                            }
                            {
                                elem.count &&
                                <div style={{ color: "grey", fontSize: "12px" }}>
                                    <span>{containerConstants.formatString(containerConstants.Qty)} : </span>
                                    <span>{elem.count}</span>
                                </div>
                            }
                        </div>
                        {/* <span className="ml10">{`${index+1}. ${elem.name} (${elem.count})`}</span> */}
                    </div>
                )
            }
        });
    }

    render() {
        return (
            <div>
                <div className="curb-side-web-order-info-details">
                    <div className="curb-side-order-info-details">
                        {this.renderOrderAttributes()}
                    </div>
                </div>
            </div>
        )
    }
}