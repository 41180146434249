const UNIVERSAL_SEARCH = {
    SET_UNIVERSAL_SEARCH_DATA: 'SET_UNIVERSAL_SEARCH_DATA',
    SET_THEME: 'SET_THEME',
    ENCODED_STRING:"encodedString",
    MERCHNAT_CODE:"merchantCode",
    LANG:"lang",
    HIDE:"hide",
    FOOTER:"footer",
    HEADER:"header",
    BACKGROUND:"background",
    PARAM1:"param1",
    PARAM2:"param2",
    PARAM3:"param3",
    PARAM4:"param4",
    PARAM5:"param5",
    SET_LOADING:"SET_LOADING",
    CI_LINK:"CI_LINK",
    ERROR:"ERROR",
    SET_SHIPMENTLIST:"shipmentList",
    COMBINATION_INDEX:"combinationIndex",  
  };
  
  export default UNIVERSAL_SEARCH;