const romanianStrings = {
    YourShipmentHasBeenDelivered:'Coletul dvs a fost livrat',
    DeliveredBy:'Livrat de',
    RateYourExperience:'Acordati o nota experientei dvs',
    ThankYouForYourValuableFeedback:'Va multumim pentru feedback',
    YourFeedbackWillContinuallyHelpUsImproveOurServices:'Feedback-ul dvs ne va ajuta sa ne imbunatatim serviciile',
    DeliveryFailed:'Livrare esuata',
    AddDeliveryPreferences:'Adaugati preferinte de livrare',
    YourDeliveryPreferenceAreSavedSuccessFully:'Preferintele dvs de livrare au fost salvate cu succes',
    WeAreUnableToTrackYourShipmentRightNow:'Nu putem urmari coletul dvs in acest moment',
    PleaseUpdateLocationInsideCircle:'Va rugam sa va reactualizati locatia in interiorul cercului',
    Ok:'Ok',
    Update:'Reactualizare',
    PickCurrentLocation:'Alegeti locatia actuala',
    SearchFor:'Cautati',
    ThisSchedulingLinkHasExpired:'Linkul de livrare a expirat',
    WeWillShareANewLinkWithYouShortly:'Va vom trimite un nou link in scurt timp',
    UhHo:'Ups!',
    NeedHelp:'Aveti nevoie de ajutor?',
    CallSupport:'Contactati serviciul de relatii cu clientii',
    EmailSupport:'Trimiteti un email serviciului de relatii cu clientii',
    DeliveryAt:'Livrare la',
    DeliveredAt: 'Livrat la',
    ContactNo:'Nr. de contact',
    TrackOnMap:'Urmariti pe harta',
    MessageToExecutive:'Mesaj catre Curier',
    Cancel:'Anulare',
    Send:'Trimitere',
    Executive:'Curier',
    Comments:'Comentarii (optional)',
    SubmitFeedback:'TRIMITERE FEEDBACK',
    TrackingNo:'Nr. colet',
    Details:'Detalii',
    Characters:'Caractere',
    Updating:'actualizare...    ',
    OopsSomethingWentWrongPleaseTryAfterSomeTime:'Hopa! Ceva nu a funcționat corect, vă rugăm să încercați mai târziu    ',
    NotListedAboveSendACustomMessage:'Nu este menționat mai sus, trimiteți un mesaj personalizat    ',
    ChangeDeliveryLocation:'Schimbare locație de livrare ',
    AttemptedBy:'Încercat de',
    DeliveredTo:'Livrat la ',
    SendMessage:'TRIMITERE MESAJ',
    VIEW_DETAILS:'Vizualizare detalii    ',
    SAVE_LOCATION:'SALVARE LOCAȚIE    ',
    LOCATION:'locație    ',
    SEARCH_ADDRESS:'Căutare adresă    ',
    OpenHours:'Program de funcționare    ',
    Note:'Notă',
    Address:'Adresă    ',
    Nearest: 'Cel mai aproape    ',
    ContactNumber:'Număr contact    ',
    FAQS:'Întrebări frecvente    ',
    HowManyTimesCanIChangeMyPickUpPoint: 'De câte ori pot schimba punctul de ridicare?    ',
    YouCanChangeItOnly: 'Îl puteți schimba doar de    ',
    GoBack:'    Înapoi    ',
    SetAsPickupStore:'Setare ca depozit de ridicare    ',
    MapView:'Vizualizare hartă    ',
    SearchForPickupPointNearYou:'Căutare punct de ridicare în apropierea dvs.    ',
    Arriving:'Sosire    ',
    LiveTrackingWillStart:'Va începe căutarea în direct    ',
    DriverTrackerWillGetActivatedOnceTheyAreOnWay:  'Dispozitivul de urmărire al conducătorului auto se va activa atunci când sunt pe drum    ',
    TrackerTimeline:'Orar dispozitiv de urmărire    ',
    PM:'PM',
    AM:'AM',
    TodayAt:'astăzi la',
    TomorrowAt:'mâine la    ',
    Today:'Astăzi    ',
    Tomorrow:'Mâine',
    On:'la',
    In:'în',
    Soon:'curând    ',
    Min:'min',
    Mins:'minute',
    Items:'ARTICOLE',
    ViewDetails: 'Vizualizare detalii    ',
    DeliveryInformation:'Informații privind livrarea    ',
    TrackingHistory:'Istoric urmărire    ',
    StayConnected:'Rămâneți conectat(ă)    ',
    YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'Ați încercat deja să efectuați o plată în ultimele minute. Doriți să anulați tranzacțiile anterioare    ',
    No: 'NU',
    yes:'DA',
    YourPackageWillBeDeliveredBy : 'Coletul dvs. va fi livrat de ',
    OrderDetails: 'Detalii comandă ',
    OrderInformation:'Informații privind comanda ',
    LastAttempted:'Ultima încercare',
    DeliveryBy:'Livrat de',
    Pickuppointisupdated:'Punctul de preluare este actualizat',
    MsgToFePageHeader:'Trimiteți instrucțiuni de livrare',
    MsgToFeSupportingText:'Ce vreți să-i spuneți șoferului?',
    AddYourMsgHere:'Adăugați mesajul dvs. aici',
    InstructionsSentSuccessfully:'Instrucțiunile au fost trimise cu succes',
    SomethingWentWrongPlsTryAgain:'Ceva nu a mers bine, încercați din nou.',
    times:"ori",
    TrackMovementInRealTime:"Urmăriți deplasarea în timp real",
    Hours:"hours",
    Hour:"hour",
    WriteYourFeedbackOptional:"Scrieți feedbackul dvs. (Opțional)",
    ReturnOrder: "Return Order",
    TheLinkYouAreTryingToOpenHasExpired: "Linkul pe care încercați să îl deschideți a expirat",
    SelectAnAvailableTimeSlot:"Select an available time slot",
    January:"JANUARY",
    February:"FEBRUARY",
    March :"MARCH",
    April:"APRIL",
    May:"MAY",
    June:"JUNE",
    July:"JULY",
    August:"AUGUST",
    September:"SEPTEMBER",
    October:"OCTOBER",
    November:"NOVEMBER",
    December:"DECEMBER",
    Monday:"MONDAY",
    Tuesday:"TUESDAY",
    Wednesday:"WEDNESDAY",
    Thursday:"THURSDAY",
    Friday:"FRIDAY",
    Saturday:"SATURDAY",
    Sunday:"SUNDAY",
    February:"FEBRUARY",
    ViewTimings: "View Timings",
    HideTimings: "Hide Timings",
    PleaseAnswerAllMandatoryQuestions:"Vă rugăm să răspundeți la toate întrebările obligatorii",
    HelpLineLink: 'Helpline Link',
    ShipmentOf: "Expediere%din",
    OtherShipments: "Alte Livrări",
    OrderInformation:"Order Information",
    OR:"sau",
    TrackShipment:"Urmăriți expedierea",
    TrackingDescription:"Introduceți detaliile de mai jos pentru a obține detalii despre expedierea dvs.",
    Continue:"Continua",
    InvalidCredentials:"Date de autentificare invalide. Vă rugăm să încercați din nou",
    ITEM: 'articol',
    ITEMS: 'articol(e)',
    OrderNo:"Nr. comandă",
    CurrentStatus:"Stare curentă",
PickupTime:"Ora de ridicare",
StoreLocation:"Store Location",
LeaveBy:"Leave By",
toReachByScheduledTime:"to reach by scheduled time",
ShareThisLink:"Share this Link",
ETAtime:"ETA time",
ParkingInstructions:"Parking instructions",
PR1_heading:"Find a Spot",
PR2_heading:"Park Safely",
PR3_heading:"Secure and Exit",
PR1_description:"Look for a marked parking space; ensure it's not restricted or reserved.",
PR2_description:" Align your vehicle within the lines and leave enough space for others.",
PR3_description:"Turn off the engine, engage the handbrake, lock the car, and take your belongings.",
willBringYourOrderShortly:"will bring your order shortly.",
PODImage:"POD Image",
CopiedtoClipboard:"Copied to Clipboard",
No_order_found:"No order found!",
Qty:"cant.",
}
export default romanianStrings;
